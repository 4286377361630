import { createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { getSupport, getSupportchat } from "../actions/adminActions";

const initialState = {
  supportdata: null,
  chat: null,
};

export const supportSlice = createSlice({
  name: "supportSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getSupport.fulfilled, (state, { payload }) => {
        console.log(payload);
        if (payload.success) {
          state.supportdata = payload.data;
        } else if (!payload?.success) {
          toast.error(payload?.message);
        }

      })
      builder.addCase(getSupportchat.fulfilled, (state, { payload }) => {
       
        if (payload.success) {
          state.chat = payload.data;
        } else if (!payload?.success) {
          toast.error(payload?.message);
        }
      });
  },
});

export default supportSlice.reducer;