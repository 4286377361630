import React from "react";
import { Link } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";
import Layout from "../Components/Layout/Layout";
import { Button } from "react-bootstrap";

export default function UserVerification() {
  return (
    <Layout>
      <div className="right-top">
        <div className="heading-top">
          <h2>USER VERIFICATION</h2>
        </div>
        <hr />
        <Container fluid>
          <div className="cmn-top-fields">
            <Row>
              <Col xxl={3} xl={3} lg={4} md={4}>
                <Form.Group>
                  <Form.Control type="search" placeholder="Keyword Search.." />
                </Form.Group>
              </Col>
              <Col xxl={7} xl={7} lg={4} md={5}>
                <Form.Select aria-label="Default select example">
                  <option>All</option>
                  <option value="1">Property 1</option>
                  <option value="2">Property 2</option>
                  <option value="3">Property 3</option>
                </Form.Select>
              </Col>
              <Col
                xxl={2}
                xl={2}
                lg={4}
                md={2}
                className="d-flex justify-content-between align-items-center"
              ></Col>
            </Row>
          </div>
        </Container>
        <hr />
      </div>
      <Container fluid>
        <div className="results-sec">
          <Row>
            <Col lg={6} className="d-flex align-items-center">
              <div className="show-results">Showing 1 - 10 of 20 results</div>
            </Col>
            <Col lg={6}>
              <div className="select-result d-flex align-items-center justify-content-end">
                <span>Results per page</span>{" "}
                <Form.Select aria-label="Default select example">
                  <option>10</option>
                  <option value="1">11</option>
                  <option value="2">12</option>
                  <option value="3">13</option>
                </Form.Select>
              </div>
            </Col>
          </Row>
        </div>
      </Container>

      <Container fluid className="px-0">
        <div class="table-responsive">
          <Table size="sm" className="table-cmn">
            <thead>
              <tr>
                <th>S.No</th>
                <th>First Name</th>
                <th>Last Name</th>
                <th>Email</th>
                <th>Phone Number </th>
                <th>Location</th>
                <th>Profile Image </th>
                <th>Role</th>
                <th>Account Status</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>1</td>
                <td>James</td>
                <td>Dummy</td>
                <td>Dummy</td>
                <td>Dummy</td>
                <td>Dummy</td>
                <td className="user-img">
                  <img src={require("../Assets/Images/user.png")} />
                </td>
                <td>Customer</td>
                <td>
                  <Button className="account-btn">Accept</Button>{" "}
                  <Button className="account-btn">Reject</Button>{" "}
                </td>
              </tr>
              <tr>
                <td>1</td>
                <td>James</td>
                <td>Dummy</td>
                <td>Dummy</td>
                <td>Dummy</td>
                <td>Dummy</td>
                <td className="user-img">
                  <img src={require("../Assets/Images/user.png")} />
                </td>
                <td>Customer</td>
                <td>
                  <Button className="account-btn">Accept</Button>{" "}
                  <Button className="account-btn">Reject</Button>{" "}
                </td>
              </tr>
              <tr>
                <td>1</td>
                <td>James</td>
                <td>Dummy</td>
                <td>Dummy</td>
                <td>Dummy</td>
                <td>Dummy</td>
                <td className="user-img">
                  <img src={require("../Assets/Images/user.png")} />
                </td>
                <td>Customer</td>
                <td>
                  <Button className="account-btn">Accept</Button>{" "}
                  <Button className="account-btn">Reject</Button>{" "}
                </td>
              </tr>
              <tr>
                <td>1</td>
                <td>James</td>
                <td>Dummy</td>
                <td>Dummy</td>
                <td>Dummy</td>
                <td>Dummy</td>
                <td className="user-img">
                  <img src={require("../Assets/Images/user.png")} />
                </td>
                <td>Customer</td>
                <td>
                  <Button className="account-btn">Accept</Button>{" "}
                  <Button className="account-btn">Reject</Button>{" "}
                </td>
              </tr>
              <tr>
                <td>1</td>
                <td>James</td>
                <td>Dummy</td>
                <td>Dummy</td>
                <td>Dummy</td>
                <td>Dummy</td>
                <td className="user-img">
                  <img src={require("../Assets/Images/user.png")} />
                </td>
                <td>Customer</td>
                <td>
                  <Button className="account-btn">Accept</Button>{" "}
                  <Button className="account-btn">Reject</Button>{" "}
                </td>
              </tr>
              <tr>
                <td>1</td>
                <td>James</td>
                <td>Dummy</td>
                <td>Dummy</td>
                <td>Dummy</td>
                <td>Dummy</td>
                <td className="user-img">
                  <img src={require("../Assets/Images/user.png")} />
                </td>
                <td>Customer</td>
                <td>
                  <Button className="account-btn">Accept</Button>{" "}
                  <Button className="account-btn">Reject</Button>{" "}
                </td>
              </tr>
              <tr>
                <td>1</td>
                <td>James</td>
                <td>Dummy</td>
                <td>Dummy</td>
                <td>Dummy</td>
                <td>Dummy</td>
                <td className="user-img">
                  <img src={require("../Assets/Images/user.png")} />
                </td>
                <td>Customer</td>
                <td>
                  <Button className="account-btn">Accept</Button>{" "}
                  <Button className="account-btn">Reject</Button>{" "}
                </td>
              </tr>
              <tr>
                <td>1</td>
                <td>James</td>
                <td>Dummy</td>
                <td>Dummy</td>
                <td>Dummy</td>
                <td>Dummy</td>
                <td className="user-img">
                  <img src={require("../Assets/Images/user.png")} />
                </td>
                <td>Customer</td>
                <td>
                  <Button className="account-btn">Accept</Button>{" "}
                  <Button className="account-btn">Reject</Button>{" "}
                </td>
              </tr>

              <tr>
                <td>1</td>
                <td>James</td>
                <td>Dummy</td>
                <td>Dummy</td>
                <td>Dummy</td>
                <td>Dummy</td>
                <td className="user-img">
                  <img src={require("../Assets/Images/user.png")} />
                </td>
                <td>Customer</td>
                <td>
                  <Button className="account-btn">Accept</Button>{" "}
                  <Button className="account-btn">Reject</Button>{" "}
                </td>
              </tr>
            </tbody>
          </Table>
        </div>
      </Container>

      <div className="next-btn-fix">
        <Link to="#">Next</Link>
      </div>
    </Layout>
  );
}
