import React, { useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";
import Layout from "../Components/Layout/Layout";
import { Figure } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteUserAccount,
  getUserProfileById,
  suspendUserAccount,
  tempBannedAccount,
} from "../redux/actions/adminActions";
import { toast } from "react-toastify";
import { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
export default function CustomerProfile() {
  const BASE_URL = process.env.REACT_APP_HOST_NAME;
  const navigate = useNavigate();
  const { id } = useParams();
  const dispatch = useDispatch();
  const { userDetailsById } = useSelector((state) => ({
    userDetailsById: state.userMgmtSlice?.userDetailsById,
  }));

  useEffect(() => {
    dispatch(getUserProfileById(id));
  }, [id]);


  const [show, setShow] = useState(false);
  const [impType, setType] = useState();

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  console.log(userDetailsById?.status, "userDetailsById?.status");
  return (
    <Layout>
      <div className="right-top">
        <div className="heading-top-inner">
          <div>
            <h2>
              {userDetailsById?.user_type == 0
                ? "Customer"
                : userDetailsById?.user_type == 1
                  ? "Vendor"
                  : userDetailsById?.user_type == 2 && "Property Manager"}{" "}
              Profile
            </h2>
          </div>
        </div>
        <hr />
      </div>

      <Container fluid>
        <div className="product-cmn-tab">
          <Row>
            <Col lg={4}>
              <div className="product-tab-left">
                <Link to="/admin/userManagement">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="40"
                    height="42"
                    viewBox="0 0 40 42"
                    fill="none"
                  >
                    <path
                      d="M10 21L8.91379 22.0345L7.92857 21L8.91379 19.9655L10 21ZM30 19.5C30.8284 19.5 31.5 20.1716 31.5 21C31.5 21.8284 30.8284 22.5 30 22.5V19.5ZM15.5805 29.0345L8.91379 22.0345L11.0862 19.9655L17.7529 26.9655L15.5805 29.0345ZM8.91379 19.9655L15.5805 12.9655L17.7529 15.0345L11.0862 22.0345L8.91379 19.9655ZM10 19.5H30V22.5L10 22.5L10 19.5Z"
                      fill="#40413A"
                    />
                  </svg>{" "}
                  Back to previous page
                </Link>
              </div>
            </Col>
          </Row>
        </div>
      </Container>

      <Container fluid className="height-set">
        <Row>
          <Col lg={12}>
            <Row>
              {userDetailsById?.user_type == 0 ? (
                <>
                  <Col lg={4}>
                    <div className="user-profile-main h-100">
                      <h3>MEMBER INFORMATION</h3>
                      <Row>
                        <Col lg={12}>
                          <Figure className="d-flex">
                            <Figure.Image
                              width={171}
                              height={180}
                              alt="171x180"
                              src={
                                userDetailsById?.profile_image ?
                                  BASE_URL + userDetailsById?.profile_image :
                                  require("../Assets/Images/user.png")
                              }
                            />
                            <Figure.Caption>
                              <h2>
                                {userDetailsById?.first_name +
                                  " " +
                                  userDetailsById?.last_name}
                              </h2>

                              <p>
                                <span>Email - </span>
                                {userDetailsById?.email}
                              </p>
                              <p>
                                <span>Phone - </span> {userDetailsById?.country_code}{" "}
                                {userDetailsById?.phone_number}
                              </p>
                            </Figure.Caption>
                          </Figure>
                        </Col>
                      </Row>
                    </div>
                  </Col>
                  <Col lg={4}>
                    <div className="user-profile-main px-3 py-4 h-100">
                      <Row>
                        <Col lg={12}>
                          <div className="property-main mt-0">
                            <h2 className="property">Property </h2>
                            {userDetailsById?.property_detail?.map((item) => {
                              return (
                                <div className="property-name-show customer mt-0 px-0">
                                  <div>
                                    {item?.property_image?.length ? (
                                      <img
                                        src={BASE_URL + item?.property_image[0]}
                                      />
                                    ) : (
                                      <img
                                        src={require("../Assets/Images/property.png")}
                                      />
                                    )}
                                  </div>
                                  <div>
                                    <h2>{item?.property_name}</h2>
                                    <h3>
                                      {item?.property_address},{" "}
                                      {item?.property_city}{" "}
                                      {item?.property_postal_code}
                                    </h3>
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </Col>
                </>
              ) : (
                <Col lg={8}>
                  <div className="user-profile-main h-100">
                    <h3>MEMBER INFORMATION</h3>
                    <Row>
                      <Col xxl={6} xl={6} lg={6}>
                        <Figure className="d-flex">
                          <Figure.Image
                            width={171}
                            height={180}
                            alt="171x180"
                            src={userDetailsById?.profile_image ?
                              BASE_URL + userDetailsById?.profile_image :
                              require("../Assets/Images/user.png")}
                          />
                          <Figure.Caption>
                            <h2>
                              {userDetailsById?.first_name +
                                " " +
                                userDetailsById?.last_name}
                            </h2>

                            <p>
                              <span>Email - </span>
                              {userDetailsById?.email}
                            </p>
                            <p>
                              <span>Phone - </span> {userDetailsById?.country_code}{" "}
                              {userDetailsById?.phone_number}
                            </p>
                          </Figure.Caption>
                        </Figure>
                      </Col>
                      <Col xxl={6} xl={6} lg={6} className="">
                        <p>
                          <span>Address - </span> {userDetailsById?.address},{" "}
                          {userDetailsById?.city} <br />
                          {userDetailsById?.country}
                        </p>
                        <p></p>

                        <p>
                          <span>Post Code - </span>
                          {userDetailsById?.postal_code}
                        </p>
                      </Col>
                    </Row>
                  </div>
                </Col>
              )}

              <Col lg={4}>
                <div className="product-overview-right h-100">
                  <div className="product-overview-box h-100">
                    <h2>ADMIN ACTIONS</h2>

                    <button
                      onClick={() => {
                        handleShow();
                        setType('suspend')
                      }}
                      type="button"
                      className="edit-product"
                    >

                      {
                        userDetailsById?.status == "1" ? 'SUSPEND ACCOUNT' : 'SUSPENDED ACCOUNT'
                      }

                    </button>
                    <button
                      // onClick={() => {

                      // }}
                      onClick={() => {
                        handleShow();
                        setType()
                      }}
                      type="button"
                      className="edit-product"
                    >
                      DELETE / CLOSE ACCOUNT
                    </button>
                  </div>
                </div>

                {/* <div className="product-actions">
              <h2>ACTIVITY</h2>
              <hr />
              <div className="product-action-inner">
                <h3>[Admin Name] made updates to Club Profile </h3>
                <p>
                  01/01/2023 <span>13:32</span>
                </p>
              </div>
              <div className="product-action-inner">
                <h3>[Admin Name] made updates to Club Profile </h3>
                <p>
                  01/01/2023 <span>13:32</span>
                </p>
              </div>
              <div className="product-action-inner">
                <h3>[Admin Name] made updates to Club Profile </h3>
                <p>
                  01/01/2023 <span>13:32</span>
                </p>
              </div>
              <div className="product-action-inner">
                <h3>[Admin Name] made updates to Club Profile </h3>
                <p>
                  01/01/2023 <span>13:32</span>
                </p>
              </div>
              <div className="product-action-inner">
                <h3>[Admin Name] made updates to Club Profile </h3>
                <p>
                  01/01/2023 <span>13:32</span>
                </p>
              </div>
              <div className="product-action-inner">
                <h3>[Admin Name] made updates to Club Profile </h3>
                <p>
                  01/01/2023 <span>13:32</span>
                </p>
              </div>
            </div>

            <div className="product-actions">
              <div className="d-flex justify-content-between align-items-center">
                <h2>DOCUMENTS</h2>
                <button className="add-btn">ADD</button>
              </div>
              <hr />
              <div className="product-action-inner">
                <h3>Certificate of Incorporation </h3>
                <p>PDF</p>
              </div>
              <div className="product-action-inner">
                <h3>[Admin Name] made updates to Club Profile </h3>
                <p>
                  Photo ID <span>JPEG</span>
                </p>
              </div>
            </div> */}
              </Col>
            </Row>
            <Col lg={12}>
              <div className="dashboard-items profile mx-0">
                <Row>
                  <Col xxl={3} xl={3} lg={3}>
                    <div className="dash-inner-boxes small">
                      <h6>Total Orders</h6>
                      <h4>{userDetailsById?.totalOrders}</h4>
                    </div>
                  </Col>
                  <Col xxl={3} xl={3} lg={3}>
                    <div className="dash-inner-boxes small">
                      <h6> Completed Orders</h6>
                      <h4>{userDetailsById?.completedOrders}</h4>
                    </div>
                  </Col>
                  <Col xxl={3} xl={3} lg={3}>
                    <div className="dash-inner-boxes small">
                      <h6>Rejected Orders</h6>
                      <h4>{userDetailsById?.rejectedOrders}</h4>
                    </div>
                  </Col>
                  <Col xxl={3} xl={3} lg={3}>
                    <div className="dash-inner-boxes small">
                      <h6>Submitted Orders</h6>
                      <h4>{userDetailsById?.submittedOrders}</h4>
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
          </Col>
        </Row>
      </Container>

      <Modal show={show} onHide={handleClose} className="delete-popup">
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>{impType == 'suspend' ? `Are You Sure You Want To ${userDetailsById?.status == "1" ? 'Suspend' : 'Reactivate'} This Account` : 'Are You Sure You Want To Delete This Account'}</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => {

            if (impType == 'suspend') {
              dispatch(tempBannedAccount({ id: id, status: userDetailsById?.status == 0 ? 1 : 0 })).then(
                (item) => {
                  console.log(item?.payload?.success, "suxeee");
                  if (item?.payload?.success) {
                    window.location.reload()
                    toast.success(item?.payload?.message);
                    //  navigate("/admin/UserManagement")
                  }
                }
              );
            } else {
              dispatch(deleteUserAccount(id)).then((item) => {
                if (item?.payload?.success) {
                  toast.success(item?.payload?.message);
                  navigate("/admin/UserManagement");
                  handleClose()
                }
              });
            }

          }}>
            Yes
          </Button>
          <Button variant="primary" onClick={handleClose}>
            No
          </Button>
        </Modal.Footer>
      </Modal>
    </Layout>
  );
}
