import React, { useEffect, useRef, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";
import Layout from "../Components/Layout/Layout";
import { FieldArray, Formik } from "formik";
import {
  createService,
  getAllManageProperties,
  getService,
  getUsers,
  imagesaveAction,
  updateService,
} from "../redux/actions/adminActions";
import { useDispatch, useSelector } from "react-redux";

function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

export default function AddServices() {
  const BASE_URL = process.env.REACT_APP_HOST_NAME;
  let query = useQuery();
  let queryId = query.get("id");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [prevImg, setPrevImg] = useState();
  const [serviceDetail, setServiceDetail] = useState();

  const formRef = useRef();

  const { allManageProperties, totalManageProperties } = useSelector(
    (state) => ({
      allManageProperties: state.managePropertySlice.allManageProperties,
      totalManageProperties: state.managePropertySlice.totalManageProperties,
    })
  );

  useEffect(() => {
    if (queryId) {
      dispatch(getService(queryId)).then((data) => {
        setServiceDetail(data?.payload?.data);
      });
    }
  }, [queryId]);

  useEffect(() => {
    dispatch(
      getAllManageProperties({
        page: 1,
        limit: 1000000000,
        search: "",
        user_type: 0,
      })
    );
  }, []);

  console.log(allManageProperties, "serviceDetail");
  return (
    <Layout>
      <div className="right-top">
        <div className="heading-top-inner">
          <h2>Add Service</h2>

          <div>
            <hr />
          </div>
        </div>
      </div>
      <Container fluid>
        <div className="product-cmn-tab">
          <Row>
            <Col lg={4}>
              <div className="product-tab-left">
                <Link to="/admin/ManageServices">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="40"
                    height="42"
                    viewBox="0 0 40 42"
                    fill="none"
                  >
                    <path
                      d="M10 21L8.91379 22.0345L7.92857 21L8.91379 19.9655L10 21ZM30 19.5C30.8284 19.5 31.5 20.1716 31.5 21C31.5 21.8284 30.8284 22.5 30 22.5V19.5ZM15.5805 29.0345L8.91379 22.0345L11.0862 19.9655L17.7529 26.9655L15.5805 29.0345ZM8.91379 19.9655L15.5805 12.9655L17.7529 15.0345L11.0862 22.0345L8.91379 19.9655ZM10 19.5H30V22.5L10 22.5L10 19.5Z"
                      fill="#40413A"
                    />
                  </svg>{" "}
                  Back to previous page
                </Link>
              </div>
            </Col>
          </Row>
        </div>
      </Container>

      <Formik
        // enableReinitialize: true
        innerRef={formRef}
        initialValues={{
          service_name: serviceDetail?.service_name || "",
          service_description: serviceDetail?.service_description || "",
          service_image: serviceDetail?.service_image || "",
          service_price: serviceDetail?.service_price || "",
          property: serviceDetail?.property || "",
          sub_category: serviceDetail?.sub_category || [
            {
              sub_cat_name: "",
              sub_cat_description: "",
              sub_cat_price: "",
              // property: "",
              sub_cat_image: "",
              preview_img: "",
            },
          ],
        }}
        validate={(values) => {
          const errors = {};
          if (!values.service_name) {
            errors.service_name = "Required*";
          }
          if (!values.service_price) {
            errors.service_price = "Required*";
          }
          // if (!values.property) {
          //   errors.property = "Required*";
          // }
          // if (!values.sub_category || !values.sub_category.length) {
          //   errors.sub_category = "At least one sub-category is required";
          // } else {
          //   values?.sub_category?.forEach((subCat, index) => {
          //     if (!subCat.property) {
          //       if (!errors.sub_category) {
          //         errors.sub_category = [];
          //       }
          //       errors.sub_category[index] = { property: "Required*" };
          //     }

          //     if (!subCat.sub_cat_price) {
          //       if (!errors.sub_category) {
          //         errors.sub_category = [];
          //       }
          //       errors.sub_category[index] = { sub_cat_price: "Required*" };
          //     }

          //     if (!subCat.sub_cat_name) {
          //       if (!errors.sub_category) {
          //         errors.sub_category = [];
          //       }
          //       errors.sub_category[index] = { sub_cat_name: "Required*" };
          //     }
          //   });
          // }
          return errors;
        }}
        onSubmit={(values, { setSubmitting }) => {
          console.log(values, "console");
          if (serviceDetail) {
            values.id = serviceDetail?._id;
            dispatch(updateService(values)).then((data) => {
              if (data?.payload?.success) {
                navigate("/admin/ManageServices");
              }
            });
          } else {
            dispatch(createService(values)).then((data) => {
              if (data?.payload?.success) {
                navigate("/admin/ManageServices");
              }
            });
          }
          setSubmitting(false);
        }}
        render={({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          setFieldValue,
        }) => (
          <Form onSubmit={handleSubmit}>
            <Container fluid className="height-set">
              <Row className="justify-content-center ">
                <Col lg={8}>
                  <div className="customer-form-new mb-4">
                    <h2>SERVICE DETAILS</h2>
                    <hr className="cmn-border" />
                    <div className="customer-form-inner">
                      <Row>
                        <Col lg={4}>
                          <Form.Group className="mb-3 image-upload-main">
                            <Form.Label>Image</Form.Label>
                            <div className="input-image-show">
                              <Form.Control
                                type="file"
                                placeholder="Upload Image"
                                name="service_image"
                                onChange={(e) => {
                                  //setFieldValue('service_image', e.target.files[0]);

                                  let formDatavalue = new FormData();
                                  formDatavalue.append(
                                    "property_image",
                                    e.target.files[0]
                                  );

                                  dispatch(imagesaveAction(formDatavalue)).then(
                                    function (data) {
                                      if (data.payload) {
                                        setFieldValue(
                                          "service_image",
                                          data.payload?.data
                                        );
                                      }
                                    }
                                  );
                                  setPrevImg(
                                    URL.createObjectURL(e.target.files[0])
                                  );
                                }}
                              />
                              {prevImg ? (
                                <img src={prevImg} />
                              ) : values.service_image ? (
                                <img
                                  src={`${BASE_URL}/${values.service_image}`}
                                />
                              ) : (
                                <p>Upload Image</p>
                              )}
                            </div>
                          </Form.Group>
                        </Col>
                        <Col lg={8}>
                          <Form.Group className="mb-3">
                            <Form.Label>Title</Form.Label>
                            <Form.Control
                              type="text"
                              placeholder="Type Here"
                              name="service_name"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.service_name}
                            />
                            <span className="formik-errors">
                              {errors.service_name &&
                                touched.service_name &&
                                errors.service_name}
                            </span>
                          </Form.Group>
                          <Form.Group className="mb-3">
                            <Form.Label>Price of the service</Form.Label>
                            <Form.Control
                              type="text"
                              placeholder="Type Here"
                              name="service_price"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.service_price}
                            />
                            <span className="formik-errors">
                              {errors.service_price &&
                                touched.service_price &&
                                errors.service_price}
                            </span>
                          </Form.Group>
                        </Col>
                        <Col lg={12}>
                          <Form.Group className="mb-3">
                            <Form.Label>Description</Form.Label>
                            <Form.Control
                              as="textarea"
                              rows={3}
                              placeholder="Type Here"
                              name="service_description"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.service_description}
                            />
                            <span className="formik-errors">
                              {errors.service_description &&
                                touched.service_description &&
                                errors.service_description}
                            </span>
                          </Form.Group>
                        </Col>
                        <Col lg={12}>
                          <div className="admin-box-select">
                            <Form.Group className="mb-3">
                              <Form.Label>
                                Choose property
                              </Form.Label>

                              <Form.Select
                                onChange={handleChange}
                                name={`property`}
                                value={
                                  values.property
                                }
                                aria-label="Default select example"
                              >
                                <option value="">
                                  Select Property
                                </option>
                                {allManageProperties?.map(
                                  (item) => {
                                    return (
                                      <option value={item?._id}>
                                        {item?.property_name}
                                      </option>
                                    );
                                  }
                                )}
                              </Form.Select>
                              {/* <span className="formik-errors">
                                {errors.property &&
                                  touched.property &&
                                  errors.property}
                              </span> */}
                            </Form.Group>
                          </div>
                        </Col>

                      </Row>
                    </div>
                  </div>
                  <div className="customer-form-new mb-4">
                    <div className="customer-form-inner">
                      <Row>
                        <FieldArray
                          name="sub_category"
                          render={(arrayHelpers) => (
                            <div>
                              {values.sub_category?.map((item, index) => {
                                return (
                                  <>
                                    <Col lg={12}>
                                      <Form.Group className="mb-3">
                                        <div className="d-flex justify-content-between">
                                          <Form.Label className="text-uppercase">
                                            Add Sub-Categories
                                          </Form.Label>
                                          <div className="d-flex add-service-btns">
                                            <button
                                              type="button"
                                              onClick={() => {
                                                arrayHelpers.remove(index);
                                              }}
                                              className="add-btn-category"
                                            >
                                              Remove
                                            </button>
                                            <button
                                              type="button"
                                              onClick={() =>
                                                arrayHelpers.push({
                                                  sub_cat_name: "",
                                                  sub_cat_description: "",
                                                  sub_cat_price: "",
                                                  property: "",
                                                  service_id: "",
                                                  sub_cat_image: "",
                                                })
                                              }
                                              className="add-btn-category"
                                            >
                                              Add
                                            </button>
                                          </div>
                                        </div>
                                        <div className="customer-form-inner-new">
                                          <Row>
                                            <Col lg={4}>
                                              <Form.Group className=" mb-3 image-upload-main">
                                                <Form.Label>Image</Form.Label>
                                                <div className="input-image-show">
                                                  <Form.Control
                                                    type="file"
                                                    placeholder="Upload Image"
                                                    onChange={(e) => {
                                                      let formDatavalue =
                                                        new FormData();
                                                      formDatavalue.append(
                                                        "property_image",
                                                        e.target.files[0]
                                                      );

                                                      dispatch(
                                                        imagesaveAction(
                                                          formDatavalue
                                                        )
                                                      ).then(function (data) {
                                                        if (data.payload) {
                                                          setFieldValue(
                                                            `sub_category.${index}.sub_cat_image`,

                                                            data.payload?.data
                                                          );
                                                        }
                                                      });

                                                      setFieldValue(
                                                        `sub_category.${index}.preview_img`,
                                                        URL.createObjectURL(
                                                          e.target.files[0]
                                                        )
                                                      );
                                                    }}
                                                  />
                                                  {values.sub_category[index]
                                                    .sub_cat_image ? (
                                                    <img
                                                      src={`${BASE_URL}/${values.sub_category[index].sub_cat_image}`}
                                                    />
                                                  ) : (
                                                    <p>Upload Image</p>
                                                  )}
                                                </div>
                                              </Form.Group>
                                            </Col>
                                            <Col lg={8}>
                                              <Form.Group className="mb-3">
                                                <Form.Label>
                                                  Add Categories
                                                </Form.Label>
                                                <Form.Control
                                                  type="text"
                                                  placeholder="Add Categories....."
                                                  onChange={handleChange}
                                                  name={`sub_category.${index}.sub_cat_name`}
                                                  value={
                                                    values.sub_category[index]
                                                      .sub_cat_name
                                                  }
                                                />
                                                {/* {errors.sub_category &&
                                                  errors.sub_category[index] &&
                                                  errors.sub_category[index]
                                                    .sub_cat_name &&
                                                  touched.sub_category &&
                                                  touched.sub_category[index] &&
                                                  touched.sub_category[index]
                                                    .sub_cat_name && (
                                                    <div className="formik-errors">
                                                      {
                                                        errors.sub_category[
                                                          index
                                                        ].sub_cat_name
                                                      }
                                                    </div>
                                                  )} */}
                                              </Form.Group>

                                              <Form.Group className="mb-3">
                                                <Form.Label>
                                                  Add Price
                                                </Form.Label>
                                                <Form.Control
                                                  type="number"
                                                  placeholder="Price"
                                                  onChange={handleChange}
                                                  name={`sub_category.${index}.sub_cat_price`}
                                                  value={
                                                    values.sub_category[index]
                                                      .sub_cat_price
                                                  }
                                                />
                                                {/* {errors.sub_category &&
                                                  errors.sub_category[index] &&
                                                  errors.sub_category[index]
                                                    .sub_cat_price &&
                                                  touched.sub_category &&
                                                  touched.sub_category[index] &&
                                                  touched.sub_category[index]
                                                    .sub_cat_price && (
                                                    <div className="formik-errors">
                                                      {
                                                        errors.sub_category[
                                                          index
                                                        ].sub_cat_price
                                                      }
                                                    </div>
                                                  )} */}
                                              </Form.Group>
                                            </Col>
                                            <Form.Group className="">
                                              <Form.Label>
                                                Description
                                              </Form.Label>
                                              <Form.Control
                                                as="textarea"
                                                rows={3}
                                                placeholder="Type Here"
                                                onChange={handleChange}
                                                name={`sub_category.${index}.sub_cat_description`}
                                                value={
                                                  values.sub_category[index]
                                                    .sub_cat_description
                                                }
                                              />
                                            </Form.Group>
                                          </Row>
                                        </div>
                                      </Form.Group>
                                    </Col>
                                    {/* <Col lg={12}>
                                      <div className="admin-box-select">
                                        <Form.Group className="mb-3">
                                          <Form.Label>
                                            Choose property
                                          </Form.Label>

                                          <Form.Select
                                            onChange={handleChange}
                                            name={`sub_category.${index}.property`}
                                            value={
                                              values.sub_category[index]
                                                .property
                                            }
                                            aria-label="Default select example"
                                          >
                                            <option value="">
                                              Select Property
                                            </option>
                                            {allManageProperties?.map(
                                              (item) => {
                                                return (
                                                  <option value={item?._id}>
                                                    {item?.property_name}
                                                  </option>
                                                );
                                              }
                                            )}
                                          </Form.Select>
                                        
                                        </Form.Group>
                                      </div>
                                    </Col> */}

                                    {/* <button
                                      type="button"
                                      onClick={() => {
                                        arrayHelpers.remove(index);
                                      }}
                                      className="add-btn-category"
                                    >
                                      Remove
                                    </button> */}
                                  </>
                                );
                              })}
                            </div>
                          )}
                        />

                        <Col
                          lg={12}
                          className="d-flex justify-content-center mt-3"
                        >
                          <button type="submit" className="add-btn">
                            Submit
                          </button>
                        </Col>
                      </Row>
                    </div>
                  </div>
                </Col>
              </Row>
            </Container>
          </Form>
        )}
        enableReinitialize={true}
      />
    </Layout>
  );
}
