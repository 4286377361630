export const pages = [
    {label:"ALL", status:10},
    {label:"Approved", status:5},
    {label:"Ongoing",status: 2},
    {label:"Pending", status: 1},
    {label:"Rejected", status:4 },
    {label:"Completed", status:3 },
    {label:"Submitted/In Queue", status: 0}
]

export const permissionObj = [
    {label:"VIEW USER SENSITIVE DATA", key: 'view_user_sensitive_data', },
    {label:"EDIT DATA", key: 'edit_data', },
    // {label:"DASHBOARD", key: 'dashboard', },
    {label:"User Management", key: 'user_mngmnt', },
    {label:"Account Activation", key: 'account_activation', },
    {label:"Manage Orders", key: 'manage_orders', },
    {label:"Manage Properties", key: 'manage_properties', },
    {label:"Support", key: 'support', },
    {label:"Manage Services", key: 'manage_services', },
    {label:"Manage Administrator", key: 'manage_admins', },
    {label:"Manage FAQs", key: 'manage_faqs', },
    {label:"Notification", key: 'notification', },

  ]