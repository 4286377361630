import { configureStore } from "@reduxjs/toolkit";
import userMgmtReducer from "./reducers/userMgmtSlice";
import dashboardReducer from "./reducers/dashboardSlice";
import managePropertySlice from "./reducers/managePropertySlice";
import adminSlice from "./reducers/adminSlice";
import supportSlice from "./reducers/supportSlice";

export const store = configureStore({
  reducer: {
    userMgmtSlice: userMgmtReducer,
    dashboardSlice: dashboardReducer,
    managePropertySlice: managePropertySlice,
    adminSlice: adminSlice,
    supportData: supportSlice
  },
});
