import { createSlice } from "@reduxjs/toolkit";
import {

  activationRequest,
  approvedAccount,
  editUserDetails,
  getAccountActivationList,
  getAllOrders,
  getAllUsers,
  getOrderDetails,
  getUserDetails,
  getUserProfileById,
  getUsers,
  tempBannedAccount,
  tempBannedAccountCustomer,
} from "../actions/adminActions";
import { toast } from "react-toastify";

const initialState = {
  users: [],
  totalUsers: null,
  userDetails: null,
  totalAllUsers: null,
  allUsers: [],
  userDetailsById: null,
  totalOrders: null,
  orders: [],
  activationList: [],
  totalActivation: null,
  orderDetails: null,
  ActivationList:null
};

export const userMgmtSlice = createSlice({
  name: "userMgmtSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getUsers.fulfilled, (state, { payload }) => {
        console.log(payload, "payload");
        if (payload?.success) {
          state.users = payload.data.user;
          state.totalUsers = payload.data.totalUser;
        } else if (!payload?.success) {
          toast.error(payload?.message);
        }
      })
      .addCase(getUserDetails.fulfilled, (state, { payload }) => {
        if (payload?.success) {
          state.userDetails = payload.data;
        } else if (!payload?.success) {
          toast.error(payload?.message);
        }
      })
      .addCase(editUserDetails.fulfilled, (state, { payload }) => {
        if (payload?.success) {
          state.userDetails = state.payload?.data;
          toast.success(payload?.message);
        } else if (!payload?.success) {
          toast.error(payload?.message);
        }
      })
      .addCase(getAllUsers.fulfilled, (state, { payload }) => {
        console.log(payload, "userMgmtSlice");
        if (payload?.success) {
          state.allUsers = payload.data.user;
          state.totalAllUsers = payload.data.totalUser;
        } else if (!payload?.success) {
          toast.error(payload?.message);
        }
      })
      .addCase(tempBannedAccount.fulfilled, (state, { payload }) => {
        if (payload?.success) {

          state.allUsers = state.allUsers?.map((item) => {
            if (item?._id == payload?.data?._id) {
              return payload?.data
            }
            else {
              return item
            }
          });
          toast.success(payload?.message);
        } else if (!payload?.success) {
          toast.error(payload?.message);
        }
      })
      .addCase(tempBannedAccountCustomer.fulfilled, (state, { payload }) => {
        if (payload?.success) {

          state.users = state.users?.map((item) => {
            if (item?._id == payload?.data?._id) {
              return payload?.data
            }
            else {
              return item
            }
          });
          toast.success(payload?.message);
        } else if (!payload?.success) {
          toast.error(payload?.message);
        }
      })
      .addCase(getUserProfileById.fulfilled, (state, { payload }) => {
        if (payload?.success) {
          state.userDetailsById = payload.message;
        } else if (!payload?.success) {
          toast.error(payload?.message);
        }
      })
      .addCase(getAllOrders.fulfilled, (state, { payload }) => {
        console.log(payload, "payload");
        if (payload?.success) {
          state.orders = payload.data.orders;
          state.totalOrders = payload.data.totalCount;
        } else if (!payload?.success) {
          toast.error(payload?.message);
        }
      })
      .addCase(activationRequest.fulfilled, (state, { payload }) => {
        console.log(payload, "payload");
        if (payload?.success) {
          state.activationList = payload.data.users;
          state.totalActivation = payload.data.totalCount;
        } else if (!payload?.success) {
          toast.error(payload?.message);
        }
      })
      .addCase(approvedAccount.fulfilled, (state, { payload }) => {
        if (payload?.success) {

          state.activationList = payload?.data?.is_approved ? state.activationList?.filter((item) => item?._id != payload?.data?._id) : state.activationList?.map((item) => {
            if (item?._id == payload?.data?._id) {
              return payload?.data
            }
            else {
              return item
            }
          });
          toast.success(payload?.message);
        } else if (!payload?.success) {
          toast.error(payload?.message);
        }
      })
      .addCase(getOrderDetails.fulfilled, (state, { payload }) => {
        if (payload?.success) {
          state.orderDetails = payload.data;
        } else if (!payload?.success) {
          toast.error(payload?.message);
        }
      })
      .addCase(getAccountActivationList.fulfilled, (state, { payload }) => {

        if (payload?.success) {
          state.ActivationList = payload.data;
        } else if (!payload?.success) {
          toast.error(payload?.message);
        }
      })


  },
});

export default userMgmtSlice.reducer;
