import React, { useEffect, useRef, useState } from "react";
import { Link, useParams } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";
import Layout from "../Components/Layout/Layout";
import { useDispatch, useSelector } from "react-redux";
import { adminchatmessages, getSupportchat } from "../redux/actions/adminActions";
import Profile from "../../src/Assets/Images/favicon.png"

export default function SupportChat() {
  const data = useSelector((state) => state.supportData.chat);
  console.log(data, "chat");
  const dispatch = useDispatch();
  const BASE_URL = process.env.REACT_APP_HOST_NAME;
  const chatContainerRef = useRef(null);
  const [message, setMessage] = useState("");
  const [flag, setflag] = useState(true);

  const { id } = useParams();
  console.log(id);

  useEffect(() => {
    dispatch(getSupportchat(id));
  }, [flag]);

  const handleclick = (e) => {
    e.preventDefault();

    dispatch(
      adminchatmessages({
        message: message,
        support_id: id,
        sender: 1,
      })
    );
    setMessage("");
    setflag(!flag);
    // if (messageInputRef.current) {
    //   // Do something with the input element
    //   messageInputRef.current.focus(); // For example, focusing on the input element
    // }
  };

  useEffect(() => {
    // Scroll to the bottom of the chat area
    console.log("first");
    chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
  }, [data]);

  return (
    <Layout>
      <div className="right-top">
        <div className="heading-top-inner">
          <div>
            <h2>Support Chat</h2>
          </div>
          <div></div>
        </div>
        <hr />
      </div>

      <Container fluid>
        <div className="product-cmn-tab">
          <Row>
            <Col lg={12}>
              <div className="product-tab-left">
                <Link to="/admin/support">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="40"
                    height="42"
                    viewBox="0 0 40 42"
                    fill="none"
                  >
                    <path
                      d="M10 21L8.91379 22.0345L7.92857 21L8.91379 19.9655L10 21ZM30 19.5C30.8284 19.5 31.5 20.1716 31.5 21C31.5 21.8284 30.8284 22.5 30 22.5V19.5ZM15.5805 29.0345L8.91379 22.0345L11.0862 19.9655L17.7529 26.9655L15.5805 29.0345ZM8.91379 19.9655L15.5805 12.9655L17.7529 15.0345L11.0862 22.0345L8.91379 19.9655ZM10 19.5H30V22.5L10 22.5L10 19.5Z"
                      fill="#40413A"
                    />
                  </svg>{" "}
                  Back to previous page
                </Link>
              </div>
            </Col>
          </Row>
        </div>
      </Container>

      <Container fluid>
        <Row className="justify-content-center">
          <Col lg={8}>
            <div className="support-chat-box-main">
              <div className="support-inner">
                <h2>User Support</h2>
                <div
                  fluid
                  ref={chatContainerRef}
                  className="support-msg-box"
                >
                  {data?.chat?.map((x) => {
                    if (x?.sender == 0) {
                      return (
                        <div className="user-box left-chat-part">
                          <div className="user-top-hdng">
                            <img
                            src={
                              data?.sender_id?.profile_image
                                ? BASE_URL +
                                  "/" +
                                  data?.sender_id?.profile_image
                                : require("../Assets/Images/user.png")
                            }
                            />
                            <h3>
                              {data?.sender_id?.first_name + " " +
                                data?.sender_id?.last_name || "N/A"}
                            </h3>
                          </div>

                          <div className="msg-w-tym">
                            <div className="user-msg-box">
                              <p>{x?.message}</p>
                            </div>
                            <span>
                              {x?.updatedAt
                                ? new Date(x?.updatedAt).toLocaleTimeString()
                                : null}
                            </span>
                          </div>
                        </div>
                      );
                    } else if (x?.sender == 1) {
                      return (
                        <div className="user-box right-chat-part">
                          <div className="user-top-hdng">
                            <h3>You</h3>
                            <img src={Profile} />
                          </div>

                          <div className="right-msg-text">
                            <div className="msg-w-tym">
                              <div className="user-msg-box">
                                <p>{x?.message}</p>
                              </div>
                              <span>
                                {x?.updatedAt
                                  ? new Date(x?.updatedAt).toLocaleTimeString()
                                  : null}
                              </span>
                            </div>
                          </div>
                        </div>
                      );
                    }
                  })}
                </div>
              </div>

              <form>
                <div class="send-box">
                  <div class="mb-3 input-group">
                    <input
                      placeholder="Type your message here..."
                      aria-label="send"
                      aria-describedby="basic-addon2"
                      name="message"
                      type="text"
                      class="send-feild form-control"
                      value={message}
                      onChange={(e) => setMessage(e.target.value)}
                    />
                    <button
                      class="input-group-text"
                      id="basic-addon2"
                      onClick={handleclick}
                    >
                      <img
                        src={require("../Assets/Images/toggle.svg").default}
                      />
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </Col>
        </Row>
      </Container>
    </Layout>
  );
}
