import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";
import Layout from "../Components/Layout/Layout";
import { Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteProperty,
  getAllManageProperties,
} from "../redux/actions/adminActions";
import Pagination from "../Components/Layout/elements/Pagination";

export default function ManageProperties() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const BASE_URL = process.env.REACT_APP_HOST_NAME;
  const { allManageProperties, totalManageProperties } = useSelector(
    (state) => ({
      allManageProperties: state.managePropertySlice.allManageProperties,
      totalManageProperties: state.managePropertySlice.totalManageProperties,
    })
  );

  const [search, setSearch] = useState("");

  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);

  useEffect(() => {
    dispatch(
      getAllManageProperties({
        page: search ? 1 : page,
        limit: limit,
        search: search,
        user_type: 0,
      })
    );
  }, [page, limit, search]);

  return (
    <Layout>
      <div className="right-top">
        <div className="heading-top">
          <h2>Manage Properties</h2>
        </div>
        <hr />
        <Container fluid>
          <div className="cmn-top-fields">
            <Row>
              <Col xxl={3} xl={3} lg={4} md={4}>
                <Form.Group>
                  <Form.Control
                    type="search"
                    placeholder="Keyword Search.."
                    onChange={(e) => setSearch(e.target.value)}
                  />
                </Form.Group>
              </Col>

              <Col
                xxl={9}
                xl={9}
                lg={8}
                md={8}
                className="d-flex justify-content-end align-items-center"
              >
                <div className="cmn-btn">
                  <Link to="/admin/Add-property">ADD</Link>
                </div>
              </Col>
            </Row>
          </div>
        </Container>
        <hr />
      </div>
      <Container fluid>
        <div className="results-sec">
          <Row>
            <Col lg={6} className="d-flex align-items-center">
              <div className="show-results">
                Showing {1 + (page - 1) * limit} -{" "}
                {allManageProperties?.length + (page - 1) * limit} of{" "}
                {totalManageProperties} results
              </div>
            </Col>
            <Col lg={6}>
              <div className="select-result d-flex align-items-center justify-content-end">
                <span>Results per page</span>{" "}
                <Form.Select
                  onChange={(e) => setLimit(e.target.value)}
                  aria-label="Default select example"
                >
                  <option value="10">10</option>
                  <option value="15">15</option>
                  <option value="20">20</option>
                  <option value="25">25</option>
                </Form.Select>
              </div>
            </Col>
          </Row>
        </div>
      </Container>

      <Container fluid className="px-0">
        <div class="table-responsive">
          <Table size="sm" className="table-cmn">
            <thead>
              <tr>
                <th>S.No</th>
                <th>Property Name</th>
                <th>Address</th>
                <th>City</th>
                <th>Postal Code </th>
                <th>Picture</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {allManageProperties?.map((item, i) => {
                return (
                  <tr key={item?._id}>
                    <td>{i + 1 + (page - 1) * limit}</td>
                    <td>{item?.property_name || "N/A"}</td>
                    <td><p className="width_set">{item?.property_address || "N/A"}</p></td>
                    <td>{item?.property_city || "N/A"}</td>
                    <td>{item?.property_postal_code || "N/A"}</td>
                    <td className="user-img">
                      <img
                        src={
                          item?.property_image?.length > 0
                            ? BASE_URL + "/" + item?.property_image[0]
                            : require("../Assets/Images/user.png")
                        }
                      />
                    </td>
                    <td>
                      <Button
                        className="account-btn"
                        onClick={() =>
                          navigate(`/admin/Add-property?id=${item?._id}`)
                        }
                      >
                        Edit
                      </Button>{" "}
                      <Button
                        onClick={() => dispatch(deleteProperty(item?._id))}
                        className="account-btn"
                      >
                        Delete
                      </Button>{" "}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
          {allManageProperties?.length == 0 && (
            <p className="no-data-found"> No data is available yet.</p>
          )}
        </div>
      </Container>

      <div className="next-btn-fix">
        <Pagination
          totalStuff={totalManageProperties}
          limit={limit}
          setPage={setPage}
        />
      </div>
    </Layout>
  );
}
