import React, { useEffect } from "react";
import Login from "./pages/Login";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import LoginOtp from "./pages/LoginOtp";
import ForgotPassword from "./pages/ForgotPassword";
import UserManagement from "./pages/UserManagement";

import Support from "./pages/Support";
import Administrators from "./pages/Administrators";

import Dashboard from "./pages/Dashboard";

import AssignOrders from "./pages/AssignOrders";

import Supportchat from "./pages/SupportChat";
import CreateAdmin from "./pages/CreateAdmin";
import AddProperty from "./pages/AddProperty";

import CustomerManagement from "./pages/CustomerManagement";
import VendorManagement from "./pages/VendorManagement";
import ManagePropertyManagers from "./pages/ManagePropertyManagers";
import AccountActivation from "./pages/AccountActivation";
import ManageOrders from "./pages/ManageOrders";

import CustomerProfile from "./pages/CustomerProfile";
import ManageProperties from "./pages/ManageProperties";
import ManageServices from "./pages/ManageServices";
import AddServices from "./pages/AddServices";
import UserVerification from "./pages/UserVerification";
import Notification from "./pages/Notification";
import AddNotification from "./pages/AddNotification";
import ChangePassword from "./pages/ChangePassword";
import ApprovedOrders from "./pages/ApprovedOrders";
import OngoingOrders from "./pages/OngoingOrders";
import PendingOrders from "./pages/PendingOrders";
import RejectedOrders from "./pages/RejectedOrders";
import CompletedOrders from "./pages/CompletedOrders";
import SubmittedOrders from "./pages/SubmittedOrders";
import VendorProfile from "./pages/VendorProfile";
import ManagerProfile from "./pages/ManagerProfile";
import AllProfile from "./pages/AllProfile";
import ViewOrders from "./pages/ViewOrders";
import ManageFaqs from "./pages/ManageFaqs";
import AddFaqs from "./pages/AddFaqs";
import PrivateRoute from "./routes/PrivateRoute";
import { dashboard, getAdminById } from "./redux/actions/adminActions";
import { useDispatch, useSelector } from "react-redux";
import ResetPassword from "./pages/ResetPassword";
import AccountActivationRejected from "./pages/AccountActivationRejected";

function App() {
  const { dash } = useSelector((state) => ({
    dash: state.dashboardSlice.dashboard,
  }));
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(dashboard());
    const id = sessionStorage.getItem("id");
    if (id) {
      dispatch(getAdminById(id)).then((data) => {
        console.log(data);
      });
    }
  }, []);

  console.log(dash, "dash");
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/*" element={<Navigate to="/" />} />
          <Route path="/" element={<Login />} />
          <Route path="/Otp/:id" element={<LoginOtp />} />
          <Route path="/resetPassword" element={<ResetPassword />} />
          <Route path="/Forgot-Password" element={<ForgotPassword />} />
          <Route path="admin/*" element={<Navigate to="/admin/dashboard" />} />
          <Route path="/admin" element={<PrivateRoute />}>
            <Route path="UserManagement" element={<UserManagement />} />

            <Route path="Support" element={<Support />} />
            <Route path="Administrators" element={<Administrators />} />

            <Route path="Dashboard" element={<Dashboard />} />

            <Route path="CustomerProfile/:id" element={<CustomerProfile />} />

            <Route path="AssignOrders" element={<AssignOrders />} />

            <Route path="Supportchat/:id" element={<Supportchat />} />
            <Route path="Create-Admin" element={<CreateAdmin />} />
            <Route path="Add-property" element={<AddProperty />} />

            <Route path="CustomerManagement" element={<CustomerManagement />} />

            <Route path="VendorManagement" element={<VendorManagement />} />
            <Route
              path="ManagePropertyManagers"
              element={<ManagePropertyManagers />}
            />
            <Route path="AccountActivation" element={<AccountActivation />} />
            <Route path="ManageOrders" element={<ManageOrders />} />
            <Route path="ManageProperties" element={<ManageProperties />} />
            <Route path="ManageServices" element={<ManageServices />} />
            <Route path="AddServices" element={<AddServices />} />
            <Route path="UserVerification" element={<UserVerification />} />
            <Route path="Notification" element={<Notification />} />
            <Route path="AddNotification" element={<AddNotification />} />
            <Route path="ChangePassword" element={<ChangePassword />} />
            <Route path="ApprovedOrders" element={<ApprovedOrders />} />
            <Route path="OngoingOrders" element={<OngoingOrders />} />
            <Route path="PendingOrders" element={<PendingOrders />} />
            <Route path="RejectedOrders" element={<RejectedOrders />} />
            <Route path="CompletedOrders" element={<CompletedOrders />} />
            <Route path="SubmittedOrders" element={<SubmittedOrders />} />
            <Route path="VendorProfile" element={<VendorProfile />} />
            <Route path="ManagerProfile" element={<ManagerProfile />} />
            <Route path="AllProfile" element={<AllProfile />} />
            <Route path="ViewOrders/:id" element={<ViewOrders />} />
            <Route path="ManageFaqs" element={<ManageFaqs />} />
            <Route path="AddFaqs" element={<AddFaqs />} />
          </Route>
          <Route
            path="/account-activation-rejected"
            element={<AccountActivationRejected />}
          />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
