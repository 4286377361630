import React from "react";
import { Link } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";
import Layout from "../Components/Layout/Layout";
import { Figure } from "react-bootstrap";

export default function VendorProfile() {
  return (
    <Layout>
      <div className="right-top">
        <div className="heading-top-inner">
          <div>
            <h2>Vendor Profile</h2>
          </div>
        </div>
        <hr />
      </div>

      <Container fluid>
        <div className="product-cmn-tab">
          <Row>
            <Col lg={4}>
              <div className="product-tab-left">
                <Link to="/userManagement">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="40"
                    height="42"
                    viewBox="0 0 40 42"
                    fill="none"
                  >
                    <path
                      d="M10 21L8.91379 22.0345L7.92857 21L8.91379 19.9655L10 21ZM30 19.5C30.8284 19.5 31.5 20.1716 31.5 21C31.5 21.8284 30.8284 22.5 30 22.5V19.5ZM15.5805 29.0345L8.91379 22.0345L11.0862 19.9655L17.7529 26.9655L15.5805 29.0345ZM8.91379 19.9655L15.5805 12.9655L17.7529 15.0345L11.0862 22.0345L8.91379 19.9655ZM10 19.5H30V22.5L10 22.5L10 19.5Z"
                      fill="#40413A"
                    />
                  </svg>{" "}
                  Back to previous page
                </Link>
              </div>
            </Col>
          </Row>
        </div>
      </Container>

      <Container fluid>
        <Row>
          <Col lg={12}>
            <Row>
              <Col lg={8}>
                <div className="user-profile-main h-100">
                  <h3>MEMBER INFORMATION</h3>
                  <Row>
                    <Col xxl={6} xl={6} lg={6}>
                      <Figure className="d-flex">
                        <Figure.Image
                          width={171}
                          height={180}
                          alt="171x180"
                          src={require("../Assets/Images/user.png")}
                        />
                        <Figure.Caption>
                          <h2>John Smith</h2>

                          <p>
                            <span>Email - </span>email@domain.com
                          </p>
                          <p>
                            <span>Phone - </span> +447380 605060
                          </p>
                        </Figure.Caption>
                      </Figure>
                    </Col>
                    <Col xxl={6} xl={6} lg={6} className="">
                      <p>
                        <span>Address - </span> 22 Shelton Street, London <br />
                        United Kingdom
                      </p>
                      <p></p>

                      <p>
                        <span>Post Code - </span>SW6
                      </p>
                    </Col>
                  </Row>
                </div>
              </Col>
              <Col lg={4}>
                <div className="product-overview-right">
                  <div className="product-overview-box">
                    <h2>ADMIN ACTIONS</h2>

                    <button type="button" className="edit-product">
                      SUSPEND ACCOUNT
                    </button>
                    <button type="button" className="edit-product">
                      DELETE / CLOSE ACCOUNT
                    </button>
                  </div>
                </div>
              </Col>
            </Row>
            <Row>
              <Col xxl={8} xl={12}>
                <div className="dashboard-items mx-0">
                  <Row>
                    <Col xxl={12} xl={12} lg={12}>
                      <div className="dashbox-inner-wrap">
                        <div className="d-flex justify-content-between align-items-center">
                          <h2>REVENUE OVERVIEW</h2>
                          <div className="cmn-top-fields px-0 py-0">
                            <Form.Select aria-label="Default select example">
                              <option>All</option>
                              <option value="1">Property 1</option>
                              <option value="2">Property 2</option>
                              <option value="3">Property 3</option>
                            </Form.Select>
                          </div>
                        </div>
                        <Row>
                          <Col xxl={3} xl={3} lg={3}>
                            <div className="dash-inner-boxes small">
                              <h6>Total Orders</h6>
                              <h4>100 </h4>
                            </div>
                          </Col>
                          <Col xxl={3} xl={3} lg={3}>
                            <div className="dash-inner-boxes small">
                              <h6>Completed Orders</h6>
                              <h4>30 </h4>
                            </div>
                          </Col>
                          <Col xxl={3} xl={3} lg={3}>
                            <div className="dash-inner-boxes small">
                              <h6>Rejected Orders</h6>
                              <h4>30 </h4>
                            </div>
                          </Col>
                          <Col xxl={3} xl={3} lg={3}>
                            <div className="dash-inner-boxes small">
                              <h6>Submitted Orders</h6>
                              <h4>30 </h4>
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </Col>
                    {/* <Col xxl={3} xl={4} lg={3}>
                  <div className="dashbox-side">
                    <h2>ACCOUNTS OVERVIEW</h2>
                    <div className="dash-inner-boxes">
                      <h6>ACCOUNT CURRENT </h6>
                      <h4>£2,202,330.00</h4>
                      <p>10% increase from last month</p>
                    </div>
                  </div>
                </Col> */}
                  </Row>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </Layout>
  );
}
