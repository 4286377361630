import React, { useEffect, useRef } from 'react';

function AutoCompleteSearch({ setFieldValue }) {
    const inputRef = useRef(null);

    useEffect(() => {
        // Initialize the autocomplete search
        const autocomplete = new window.google.maps.places.Autocomplete(inputRef.current, {
            componentRestrictions: { country: 'in' },
            types: ['geocode']
        });

        autocomplete.addListener('place_changed', () => {
            const place = autocomplete.getPlace();
            if (!place.geometry) {
                console.log("No details available for input: '" + place.name + "'");
                return;
            }

            const latitude = place.geometry.location.lat();
            const longitude = place.geometry.location.lng();
            setFieldValue('property_lat', latitude);
            setFieldValue('property_long', longitude);

            // Extract city and postcode
            let city = "";
            let postcode = "";
            let address = place.formatted_address;

            place.address_components.forEach(component => {
                if (component.types.includes('locality')) {
                    city = component.long_name;
                }
                if (component.types.includes('postal_code')) {
                    postcode = component.long_name;
                }
            });

            setFieldValue('property_city',city);
            setFieldValue('property_postal_code',postcode);
            setFieldValue('property_address',address);
        });

    }, []);

    return (
        <div>
            <input ref={inputRef} type="text" placeholder="Enter a location" />
        </div>
    );
}

export default AutoCompleteSearch;
