import { createSlice } from "@reduxjs/toolkit";
import { deleteAdmin, getAllAdmin, getAdminById, updateAdmin, deleteNotification, getAllNotifications, getFaq, getFaqById, deleteFaq } from "../actions/adminActions";
import { toast } from "react-toastify";

const initialState = {
    admins: [],
    totalAdmins: null,
    adminDetails: null,
    notifications:[],
    totalNotification: null,
    faqs:[],
    totalFaqs:null,
    faqDetails:null
};

export const adminSlice = createSlice({
  name: "adminSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      
      .addCase(getAllAdmin.fulfilled, (state, { payload }) => {
        if (payload?.success) {
          state.admins = payload.data.admins;
          state.totalAdmins = payload.data.totalAdmins;
        } else if (!payload?.success) {
          toast.error(payload?.message);
        }
      })
      .addCase(getAdminById.fulfilled, (state, { payload }) => {
        if (payload?.success) {
          state.adminDetails = payload.data;
        } else if (!payload?.success) {
          toast.error(payload?.message);
        }
      })
      .addCase(deleteAdmin.fulfilled, (state, { payload }) => {
        if (payload?.success) {
          console.log(payload,"payload");
          state.admins = state.admins?.filter((item)=> item?._id != payload?.data?._id);
         // toast.success(payload?.message);
        } else if (!payload?.success) {
          toast.error(payload?.message);
        }
      })
      .addCase(deleteNotification.fulfilled, (state, { payload }) => {
        if (payload?.success) {
          console.log(payload,"payload");
          state.notifications = state.notifications?.filter((item)=> item?._id != payload?.data?._id);
         // toast.success(payload?.message);
        } else if (!payload?.success) {
          toast.error(payload?.message);
        }
      })
      .addCase(getAllNotifications.fulfilled, (state, { payload }) => {
        if (payload?.success) {
          state.notifications = payload.data.notifications;
          state.totalNotification = payload.data.totalNotification;
        } else if (!payload?.success) {
          toast.error(payload?.message);
        }
      })
        
      .addCase(getFaq.fulfilled, (state, { payload }) => {
        if (payload?.success) {
          state.faqs = payload.data.users;
          state.totalFaqs = payload.data.totalCount;
        } else if (!payload?.success) {
          toast.error(payload?.message);
        }
      })
      .addCase(getFaqById.fulfilled, (state, { payload }) => {
        if (payload?.success) {
          state.faqDetails = payload.data;
        } else if (!payload?.success) {
          toast.error(payload?.message);
        }
      })
      .addCase(deleteFaq.fulfilled, (state, { payload }) => {
        if (payload?.success) {
          console.log(payload,"payload");
          state.faqs = state.faqs?.filter((item)=> item?._id != payload?.data?._id);
         // toast.success(payload?.message);
        } else if (!payload?.success) {
          toast.error(payload?.message);
        }
      })
  },
});

export default adminSlice.reducer;
