import React from "react";
import { Link } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";
import Layout from "../Components/Layout/Layout";
import { Button } from "react-bootstrap";

export default function RejectedOrders() {
  return (
    <Layout>
      <div className="right-top">
        <div className="heading-top">
          <h2>Rejected Orders</h2>
        </div>
        <hr />
        <Container fluid>
          <div className="cmn-top-fields">
            <Row>
              <Col xxl={3} xl={3} lg={4} md={4}>
                <Form.Group>
                  <Form.Control type="search" placeholder="Keyword Search.." />
                </Form.Group>
              </Col>
              <Col xxl={3} xl={3} lg={4} md={4}>
                <Form.Select aria-label="Default select example">
                  <option>All</option>
                  <option value="1">Property 1</option>
                  <option value="2">Property 2</option>
                  <option value="3">Property 3</option>
                </Form.Select>
              </Col>
              <Col
                xxl={6}
                xl={6}
                lg={4}
                md={4}
                className="d-flex justify-content-end align-items-center"
              >
                <div className="cmn-btn">
                  <Link to="#">DOWNLOAD</Link>
                </div>
              </Col>
            </Row>
          </div>
        </Container>
        <hr />
        <Container fluid>
          <div className="product-tab">
            <ul>
              <li>
                <Link to="/ManageOrders">
                  ALL <span></span>
                </Link>
              </li>
              <li>
                <Link to="/ApprovedOrders">
                  Approved <span></span>
                </Link>
              </li>
              <li>
                <Link to="/OngoingOrders">
                  Ongoing <span></span>
                </Link>
              </li>
              <li>
                <Link to="/PendingOrders">
                  Pending <span></span>
                </Link>
              </li>
              <li>
                <Link to="/RejectedOrders" className="active-tab">
                  Rejected <span></span>
                </Link>
              </li>
              <li>
                <Link to="/CompletedOrders">
                  Completed <span></span>
                </Link>
              </li>
              <li>
                <Link to="/SubmittedOrders">
                  Submitted/In Queue <span></span>
                </Link>
              </li>
            </ul>
          </div>
        </Container>
      </div>
      <Container fluid>
        <div className="results-sec">
          <Row>
            <Col lg={6} className="d-flex align-items-center">
              <div className="show-results">Showing 1 - 10 of 20 results</div>
            </Col>
            <Col lg={6}>
              <div className="select-result d-flex align-items-center justify-content-end">
                <span>Results per page</span>{" "}
                <Form.Select aria-label="Default select example">
                  <option>10</option>
                  <option value="1">11</option>
                  <option value="2">12</option>
                  <option value="3">13</option>
                </Form.Select>
              </div>
            </Col>
          </Row>
        </div>
      </Container>

      <Container fluid className="px-0">
        <div class="table-responsive">
          <Table size="sm" className="table-cmn">
            <thead>
              <tr>
                <th>Order ID#</th>
                <th>Order Created Date</th>
                <th>Client Name</th>
                <th>Property Address</th>
                <th>Service Requested </th>
                <th>Start Date</th>
                <th>Frequency</th>
                <th>Slot</th>
                <th>Instructions</th>
                <th>Status</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>1</td>
                <td>12/12/2</td>
                <td>James</td>
                <td>Smart City</td>
                <td>cleaning</td>
                <td>12/12/2</td>
                <td>Xyz</td>
                <td>xyz</td>
                <td>xyz</td>
                <td>Ongoing</td>
                <td className="order-btn">
                  <Link to="/AssignOrders">View</Link>
                </td>
              </tr>
              <tr>
                <td>1</td>
                <td>12/12/2</td>
                <td>James</td>
                <td>Smart City</td>
                <td>cleaning</td>
                <td>12/12/2</td>
                <td>Xyz</td>
                <td>xyz</td>
                <td>xyz</td>
                <td>Ongoing</td>
                <td className="order-btn">
                  <Link to="/AssignOrders">View</Link>
                </td>
              </tr>
              <tr>
                <td>1</td>
                <td>12/12/2</td>
                <td>James</td>
                <td>Smart City</td>
                <td>cleaning</td>
                <td>12/12/2</td>
                <td>Xyz</td>
                <td>xyz</td>
                <td>xyz</td>
                <td>Ongoing</td>
                <td className="order-btn">
                  <Link to="/AssignOrders">View</Link>
                </td>
              </tr>
              <tr>
                <td>1</td>
                <td>12/12/2</td>
                <td>James</td>
                <td>Smart City</td>
                <td>cleaning</td>
                <td>12/12/2</td>
                <td>Xyz</td>
                <td>xyz</td>
                <td>xyz</td>
                <td>Ongoing</td>
                <td className="order-btn">
                  <Link to="/AssignOrders">View</Link>
                </td>
              </tr>
              <tr>
                <td>1</td>
                <td>12/12/2</td>
                <td>James</td>
                <td>Smart City</td>
                <td>cleaning</td>
                <td>12/12/2</td>
                <td>Xyz</td>
                <td>xyz</td>
                <td>xyz</td>
                <td>Ongoing</td>
                <td className="order-btn">
                  <Link to="/AssignOrders">View</Link>
                </td>
              </tr>
              <tr>
                <td>1</td>
                <td>12/12/2</td>
                <td>James</td>
                <td>Smart City</td>
                <td>cleaning</td>
                <td>12/12/2</td>
                <td>Xyz</td>
                <td>xyz</td>
                <td>xyz</td>
                <td>Ongoing</td>
                <td className="order-btn">
                  <Link to="/AssignOrders">View</Link>
                </td>
              </tr>
              <tr>
                <td>1</td>
                <td>12/12/2</td>
                <td>James</td>
                <td>Smart City</td>
                <td>cleaning</td>
                <td>12/12/2</td>
                <td>Xyz</td>
                <td>xyz</td>
                <td>xyz</td>
                <td>Ongoing</td>
                <td className="order-btn">
                  <Link to="/AssignOrders">View</Link>
                </td>
              </tr>
              <tr>
                <td>1</td>
                <td>12/12/2</td>
                <td>James</td>
                <td>Smart City</td>
                <td>cleaning</td>
                <td>12/12/2</td>
                <td>Xyz</td>
                <td>xyz</td>
                <td>xyz</td>
                <td>Ongoing</td>
                <td className="order-btn">
                  <Link to="/AssignOrders">View</Link>
                </td>
              </tr>
              <tr>
                <td>1</td>
                <td>12/12/2</td>
                <td>James</td>
                <td>Smart City</td>
                <td>cleaning</td>
                <td>12/12/2</td>
                <td>Xyz</td>
                <td>xyz</td>
                <td>xyz</td>
                <td>Ongoing</td>
                <td className="order-btn">
                  <Link to="/AssignOrders">View</Link>
                </td>
              </tr>
              <tr>
                <td>1</td>
                <td>12/12/2</td>
                <td>James</td>
                <td>Smart City</td>
                <td>cleaning</td>
                <td>12/12/2</td>
                <td>Xyz</td>
                <td>xyz</td>
                <td>xyz</td>
                <td>Ongoing</td>
                <td className="order-btn">
                  <Link to="/AssignOrders">View</Link>
                </td>
              </tr>
            </tbody>
          </Table>
        </div>
      </Container>

      <div className="next-btn-fix">
        <Link to="#">Next</Link>
      </div>
    </Layout>
  );
}
