import React from "react";
import { Link } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";
import Layout from "../Components/Layout/Layout";
import { Button } from "react-bootstrap";

export default function AssignOrders() {
  return (
    <Layout>
      <div className="right-top">
        <div className="heading-top-inner">
          <div>
            <h2>Assign Orders</h2>
            <p>USER # 00001</p>
          </div>
        </div>
        <hr />
      </div>

      <Container fluid>
        <div className="product-cmn-tab">
          <Row>
            <Col lg={4}>
              <div className="product-tab-left">
                <Link to="/ManageOrders">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="40"
                    height="42"
                    viewBox="0 0 40 42"
                    fill="none"
                  >
                    <path
                      d="M10 21L8.91379 22.0345L7.92857 21L8.91379 19.9655L10 21ZM30 19.5C30.8284 19.5 31.5 20.1716 31.5 21C31.5 21.8284 30.8284 22.5 30 22.5V19.5ZM15.5805 29.0345L8.91379 22.0345L11.0862 19.9655L17.7529 26.9655L15.5805 29.0345ZM8.91379 19.9655L15.5805 12.9655L17.7529 15.0345L11.0862 22.0345L8.91379 19.9655ZM10 19.5H30V22.5L10 22.5L10 19.5Z"
                      fill="#40413A"
                    />
                  </svg>{" "}
                  Back to previous page
                </Link>
              </div>
            </Col>
          </Row>
        </div>
      </Container>

      <Container fluid>
        <Row>
          <Col lg={8}>
            <Row>
              <Col lg={12}>
                <div className="customer-form">
                  <h2 className="text-uppercase">Assign Orders</h2>
                  <hr />
                  <Row>
                    <Col lg={4}>
                      <div className="member-info-inner">
                        <h2>Service Type</h2>
                        <div className="member-inner">
                          <p>Cleaning Service</p>
                        </div>
                      </div>
                    </Col>
                    <Col lg={4}>
                      <div className="member-info-inner">
                        <h2>Pick Start date</h2>
                        <div className="member-inner">
                          <p>29 Nov 2023</p>
                        </div>
                      </div>
                    </Col>{" "}
                    <Col lg={4}>
                      <div className="member-info-inner">
                        <h2>Frequency</h2>
                        <div className="member-inner">
                          <p>Weekly</p>
                        </div>
                      </div>
                    </Col>
                    <Col lg={4} className="mt-4">
                      <div className="member-info-inner">
                        <h2>Slot</h2>
                        <div className="member-inner">
                          <p>9:00am</p>
                        </div>
                      </div>
                    </Col>
                    <Col lg={6} className="mt-4">
                      <div className="member-info-inner">
                        <h2>Address</h2>
                        <div className="member-inner">
                          <p>#123 street , New York, NY</p>
                        </div>
                      </div>
                    </Col>{" "}
                  </Row>
                </div>
              </Col>
            </Row>

            <Row>
              <Col lg={12}>
                <div className="profile-products-sec">
                  <h2 className="text-uppercase">Instructions </h2>
                  <hr />
                  <div className="admin-box-main">
                    <div className="admin-box-inner">
                      <p className="text-start">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                        Pellentesque id commodo nunc. Fusce enim ligula,
                        consectetur et velit nec, rutrum hendrerit risus. Proin
                        non tristique ipsum. Quisque nec ante dignissim,
                        lobortis tortor at, bibendum tellus. Praesent ultrices
                        odio urna, et volutpat ex placerat id. Aliquam ante
                        eros, scelerisque et leo id, convallis rhoncus odio.
                        Donec elit nibh, eleifend sit amet dignissim sed, semper
                        vitae leo.
                      </p>
                    </div>
                  </div>
                  <Row className="px-5 pt-4">
                    <Col lg={12}>
                      <div className="accept-reject-btn mb-4">
                        <Button variant="secondary">Accept</Button>{" "}
                        <Button variant="secondary">Reject</Button>{" "}
                      </div>
                    </Col>
                    <Col lg={12}>
                      <div className="admin-box-select">
                        <Form.Group className="mb-3">
                          <Form.Label>Assign Vendor</Form.Label>

                          <Form.Select aria-label="Default select example">
                            <option>Kevin</option>
                            <option value="1">Sam</option>
                            <option value="2">Nat</option>
                            <option value="3">Ronny</option>
                          </Form.Select>
                        </Form.Group>
                      </div>
                    </Col>
                    <Col lg={12}>
                      <div className="admin-box-select">
                        <Form.Group className="mb-3">
                          <Form.Label>Select Reject Reason</Form.Label>

                          <Form.Select aria-label="Default select example">
                            <option>Service date is unavailable</option>
                            <option value="1">
                              Service Time is unavailable
                            </option>
                          </Form.Select>
                        </Form.Group>
                      </div>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </Layout>
  );
}
