import React from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";

ChartJS.register(ArcElement, Tooltip, Legend);



export default function Users({dash}) {
   const data = {
    labels: [ `Customers ${parseInt(dash?.customersPercentage) || 0}%`, `Vendors ${parseInt(dash?.vendorsPercentage) || 0}%`, `Property Managers ${parseInt(dash?.propertyManagersPercentage) || 0}%`],
    datasets: [
      {
        label: "# of Votes",
        data: [dash?.customersPercentage || 0, dash?.vendorsPercentage || 0, dash?.propertyManagersPercentage || 0],
        backgroundColor: ["#1BA97D", "#087D59", "#DCF9E8"],
  
        borderWidth: 1,
      },
    ],
  };

  return <Doughnut className="gender-chart" data={data} />;
}
