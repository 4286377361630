import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";
import Layout from "../Components/Layout/Layout";
import Pagination from "../Components/Layout/elements/Pagination";
import { useDispatch, useSelector } from "react-redux";
import { changeSupportStatus, getSupport } from "../redux/actions/adminActions";
import { toast } from "react-toastify";

export default function Support() {
  const dispatch = useDispatch();

  const data = useSelector((state) => state.supportData.supportdata);
  console.log(data);

  const [search, setSearch] = useState("");
  const [flag, setflag] = useState(true);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(5);

  useEffect(() => {
    dispatch(
      getSupport({
        page: search ? 1 : page,
        limit: limit,
        search: search,
        type: 0,
      })
    );
  }, [page, limit, search, flag]);

  const handlestatus = (e, id) => {
    console.log(id)
    dispatch(
      changeSupportStatus({ support_id: id, status: e.target.value })
    ).then((res) => {
      if (res?.payload?.success) {
        toast.success(res?.payload?.message);
        setflag(!flag);
      } else {
        toast.error(res?.payload?.message);
      }
    })
  }
  return (
    <Layout>
      <div className="right-top">
        <div className="heading-top">
          <h2>USER SUPPORT</h2>
        </div>
        <hr />
        <Container fluid>
          <div className="cmn-top-fields">
            <Row>
              <Col xxl={3} xl={3} lg={4} md={4}>
                <Form.Group>
                  <Form.Control type="search" placeholder="Keyword Search.." onChange={(e) => setSearch(e.target.value)} />
                </Form.Group>
              </Col>

              {/* <Col className="d-flex justify-content-end align-items-center">
                <div className="cmn-btn">
                  <Link to="#">DOWNLOAD CSV</Link>
                </div>
              </Col> */}
            </Row>
          </div>
        </Container>
        <hr />
      </div>
      <Container fluid>
        <div className="results-sec">
          <Row>
            <Col lg={6} className="d-flex align-items-center">
              <div className="show-results">Showing 1 - 10 of 20 results</div>
            </Col>
            <Col lg={6}>
              <div className="select-result d-flex align-items-center justify-content-end">
                <span>Results per page</span>{" "}
                <Form.Select aria-label="Default select example">
                  <option value="10">10</option>
                  <option value="15">15</option>
                  <option value="20">20</option>
                  <option value="25">25</option>
                </Form.Select>
              </div>
            </Col>
          </Row>
        </div>
      </Container>

      <Container fluid className="px-0">
        <div class="table-responsive">
          <Table size="sm" className="table-cmn">
            <thead>
              <tr>
                <th>S.No.</th>
                <th>FULL NAME</th>
                <th>EMAIL</th>
                <th>Contact number</th>
                <th>MESSAGE </th>
                <th>STATUS</th>
                <th>ACTION</th>
              </tr>
            </thead>
            <tbody>
              {data?.supports?.map((x, index) => {

                return (
                  <tr>
                    <td>{index + 1}</td>
                    <td>
                      {x?.sender_id?.first_name}  {x?.sender_id?.last_name}

                      <br />
                      {/* <span className="time">12:03</span> */}
                    </td>
                    <td>
                      <Link to={`/admin/Supportchat/${x?._id}`}>{x?.sender_id?.email}</Link>
                    </td>
                    <td>{x?.sender_id?.phone_number}</td>
                    <td>
                      {x?.message}
                    </td>
                    <td>{x?.status == 1 ? "Resolved" : "Opened"}</td>
                    <td className="select-box">
                      <Form.Select value={x?.status} onChange={(e) => handlestatus(e, x?._id)} aria-label="Default select example">
                        <option value="1">Resolved</option>
                        <option value="0">Opened</option>
                      </Form.Select>
                    </td>
                  </tr>
                );
              })}

              {/* <tr>
                <td>000001</td>
                <td>
                  01/02/2023
                  <br />
                  <span className="time">12:03</span>
                </td>
                <td>
                  <Link to="/admin/Supportchat">Johnsmith@gmail.com</Link>
                </td>
                <td>Delete Account</td>
                <td>
                  I’m trying to delete my account. Could you please help ...
                </td>
                <td>Resolved</td>
                <td className="select-box">
                  <Form.Select aria-label="Default select example">
                    <option>Resolved</option>
                    <option value="1">Active</option>
                  </Form.Select>
                </td>
              </tr> */}
            </tbody>
          </Table>
        </div>
      </Container>

      <div className="next-btn-fix">
        <Pagination
          totalStuff={data?.totalSupports}
          limit={limit}
          setPage={setPage}
        />
      </div>
    </Layout>
  );
}
