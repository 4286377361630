import { createAsyncThunk } from "@reduxjs/toolkit";
import adminApi from "../../services/adminApi";
import { toast } from "react-toastify";
import Cookies from "universal-cookie";
import moment from "moment/moment";

const cookies = new Cookies();

const login = createAsyncThunk(
  "login",
  async ({ loginObj, keepMeLoggedIn }) => {
    const { data } = await adminApi.post("/auth/login", loginObj);
    if (data?.success) {
      sessionStorage.setItem("token", data?.data?.token);
      sessionStorage.setItem("id", data?.data?._id);
      if (keepMeLoggedIn) {
        cookies.set("token", data?.data?.token, {
          path: "/",
          expires: moment().add(7, "days").toDate(),
        });
      }
      toast(data?.message);
      window.location.href = "/admin/dashboard";
    } else if (!data?.success) {
      toast.error(data?.message);
    }
  }
);

const changePassword = createAsyncThunk(
  "login",
  async ({ passObj }) => {
    const { data } = await adminApi.post("/auth/changePassAdmin", passObj);
    if (data?.success) {
     
      toast(data?.message);
      window.location.href = "/admin/dashboard";
    } else if (!data?.success) {
      toast.error(data?.message);
    }
  }
);

export { login,changePassword };
