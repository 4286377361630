import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";
import Layout from "../Components/Layout/Layout";
import { Button } from "react-bootstrap";
import Pagination from "../Components/Layout/elements/Pagination";
import { deleteFaq, getFaq } from "../redux/actions/adminActions";
import { useDispatch, useSelector } from "react-redux";

export default function ManageFaqs() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const BASE_URL = process.env.REACT_APP_HOST_NAME;
  const { faqs, totalFaqs } = useSelector((state) => ({
    faqs: state.adminSlice.faqs,
    totalFaqs: state.adminSlice.totalFaqs,
  }));

  const [search, setSearch] = useState("");

  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);

  useEffect(() => {
    dispatch(
      getFaq({
        page: search ? 1 : page,
        limit: limit,
        search: search,
      })
    );
  }, [page, limit, search]);

  console.log(faqs, totalFaqs);
  return (
    <Layout>
      <div className="right-top">
        <div className="heading-top">
          <h2>Manage FAQs</h2>
        </div>
        <hr />
        <Container fluid>
          <div className="cmn-top-fields">
            <Row>
              <Col xxl={3} xl={3} lg={4} md={4}>
                <Form.Group>
                  <Form.Control
                    type="search"
                    placeholder="Keyword Search.."
                    onChange={(e) => setSearch(e.target.value)}
                  />
                </Form.Group>
              </Col>

              <Col
                xxl={9}
                xl={9}
                lg={8}
                md={8}
                className="d-flex justify-content-end align-items-center"
              >
                <div className="cmn-btn">
                  <Link to="/admin/AddFaqs">ADD</Link>
                </div>
              </Col>
            </Row>
          </div>
        </Container>
        <hr />
      </div>
      <Container fluid>
        <div className="results-sec">
          <Row>
            <Col lg={6} className="d-flex align-items-center">
              <div className="show-results">
                Showing {1 + (page - 1) * limit} -{" "}
                {faqs?.length + (page - 1) * limit} of {totalFaqs} results
              </div>
            </Col>
            <Col lg={6}>
              <div className="select-result d-flex align-items-center justify-content-end">
                <span>Results per page</span>{" "}
                <Form.Select
                  onChange={(e) => setLimit(e.target.value)}
                  aria-label="Default select example"
                >
                  <option value="10">10</option>
                  <option value="15">15</option>
                  <option value="20">20</option>
                  <option value="25">25</option>
                </Form.Select>
              </div>
            </Col>
          </Row>
        </div>
      </Container>

      <Container fluid className="px-0">
        <div class="table-responsive">
          <Table size="sm" className="table-cmn unset">
            <thead>
              <tr>
                <th>S.No</th>
                <th>Question</th>
                <th>Answer</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {faqs?.map((item, i) => {
                return (
                  <tr>
                    <td>{i + 1}</td>
                    <td>
                      <p className="width-set-dots">{item?.question}</p>{" "}
                    </td>
                    <td>
                      <p className="text-break-td">{item?.answer}</p>
                    </td>
                    <td>
                      <Button
                        onClick={() =>
                          navigate(`/admin/AddFaqs?id=${item?._id}`)
                        }
                        className="account-btn"
                      >
                        Edit
                      </Button>{" "}
                      <Button
                        onClick={() => dispatch(deleteFaq(item?._id))}
                        className="account-btn"
                      >
                        Delete
                      </Button>{" "}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
          {faqs?.length == 0 && (
            <p className="no-data-found">No data is available yet.</p>
          )}
        </div>
      </Container>

      <div className="next-btn-fix">
        <Pagination totalStuff={totalFaqs} limit={limit} setPage={setPage} />
      </div>
    </Layout>
  );
}
