import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";
import Layout from "../Components/Layout/Layout";
import { Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllManageProperties,
  getAllOrders,
} from "../redux/actions/adminActions";
import { pages } from "../utils/statics";
import DownloadCsv from "../Components/Layout/elements/DownloadCsv";
import Pagination from "../Components/Layout/elements/Pagination";
import moment from "moment";

export default function ManageOrders() {
  const dispatch = useDispatch();
  const BASE_URL = process.env.REACT_APP_HOST_NAME;
  const { orders, totalOrders, allManageProperties } = useSelector((state) => ({
    orders: state.userMgmtSlice.orders,
    totalOrders: state.userMgmtSlice.totalOrders,
    allManageProperties: state.managePropertySlice.allManageProperties,
  }));

  const [search, setSearch] = useState("");
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [activeTab, setActiveTab] = useState();
  const [activeStatus, setActiveStatus] = useState(10);
  const [propertyFilter, setPropertyFilter] = useState();

  useEffect(() => {
    dispatch(
      getAllOrders({
        page: search ? 1 : page,
        limit: limit,
        search: search,
        orderStatus: activeStatus,
        propertyFilter: propertyFilter,
      })
    );
  }, [page, limit, search, activeStatus, propertyFilter]);

  useEffect(() => {
    dispatch(
      getAllManageProperties({
        page: 1,
        limit: 1000000000,
        search: "",
        user_type: 0,
      })
    );
  }, []);

  console.log(orders, totalOrders, "orders");

  return (
    <Layout>
      <div className="right-top">
        <div className="heading-top">
          <h2>Manage Orders</h2>
        </div>
        <hr />
        <Container fluid>
          <div className="cmn-top-fields">
            <Row>
              <Col xxl={3} xl={3} lg={4} md={4}>
                <Form.Group>
                  <Form.Control
                    type="search"
                    placeholder="Keyword Search.."
                    onChange={(e) => setSearch(e.target.value)}
                  />
                </Form.Group>
              </Col>
              <Col xxl={3} xl={3} lg={4} md={4}>
                <Form.Select
                  aria-label="Default select example"
                  onChange={(e) => setPropertyFilter(e.target.value)}
                >
                  <option value="">Select Property</option>
                  {allManageProperties?.map((item) => {
                    return (
                      <option value={item?._id}>{item?.property_name}</option>
                    );
                  })}
                </Form.Select>
              </Col>
              <Col
                xxl={6}
                xl={6}
                lg={4}
                md={4}
                className="d-flex justify-content-end align-items-center"
              >
                <div className="cmn-btn">
                  <DownloadCsv data={orders} />
                </div>
              </Col>
            </Row>
          </div>
        </Container>
        <hr />
        <Container fluid>
          <div className="product-tab">
            <ul>
              {pages?.map((item) => {
                return (
                  <>
                    <li onClick={() => setActiveStatus(item?.status)}>
                      <Link
                        to="#"
                        className={activeStatus == item?.status && "active-tab"}
                      >
                        {item?.label} <span></span>
                      </Link>
                    </li>
                  </>
                );
              })}
            </ul>
          </div>
        </Container>
      </div>
      <Container fluid>
        <div className="results-sec">
          <Row>
            <Col lg={6} className="d-flex align-items-center">
            <div className="show-results">
                Showing {1 + (page - 1) * limit} -{" "}
                {orders?.length + (page - 1) * limit} of {totalOrders}{" "}
                results
              </div>
            </Col>
            <Col lg={6}>
              <div className="select-result d-flex align-items-center justify-content-end">
                <span>Results per page</span>{" "}
                <Form.Select
                  onChange={(e) => setLimit(e.target.value)}
                  aria-label="Default select example"
                >
                  <option value="10">10</option>
                  <option value="15">15</option>
                  <option value="20">20</option>
                  <option value="25">25</option>
                </Form.Select>
              </div>
            </Col>
          </Row>
        </div>
      </Container>

      <Container fluid className="px-0">
        <div class="table-responsive">
          <Table size="sm" className="table-cmn">
            <thead>
              <tr>
                <th>Order ID#</th>
                <th>Order Created Date</th>
                <th>Client Name</th>
                <th>Property Address</th>
                <th>Service Requested </th>
                <th>Start Date</th>
                <th>Frequency</th>
                <th>Slot</th>
                <th>Instructions</th>
                <th>Status</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {orders?.map((item, i) => {
                return (
                  <>
                    <tr>
                      <td>{i + 1}</td>
                      <td>
                        {moment(item?.createdAt).format("DD/MM/YYYY") || "N/A"}
                      </td>
                      <td>
                        {item?.userId?.first_name +
                          " " +
                          item?.userId?.last_name}
                      </td>
                      <td>{item?.property_id?.property_address}</td>
                      <td>{item?.service_id?.service_name}</td>
                      <td>
                        {moment(item?.start_date).format("DD/MM/YYYY") || "N/A"}
                      </td>
                      <td>{item?.frequency}</td>
                      <td>{item?.slot_name}</td>
                      <td className="table-desc">
                        <p>{item?.instructions}</p>
                      </td>
                      <td>
                        {item?.orderStatus == 0
                          ? "Submitted/In Queue"
                          : item?.orderStatus == 1
                          ? "Pending"
                          : item?.orderStatus == 2
                          ? "Ongoing"
                          : item?.orderStatus == 3
                          ? "Completed"
                          : item?.orderStatus == 4
                          ? "Rejected"
                          : item?.orderStatus == 5 && "Approved"}
                      </td>
                      <td className="order-btn">
                        <Link to={`/admin/ViewOrders/${item?._id}`}>View</Link>
                      </td>
                    </tr>
                  </>
                );
              })}
            </tbody>
          </Table>
          {orders?.length == 0 && (
            <p className="no-data-found">No data is available yet.</p>
          )}
        </div>
        <div className="next-btn-fix">
          <Pagination
            totalStuff={totalOrders}
            limit={limit}
            setPage={setPage}
          />
        </div>
      </Container>
    </Layout>
  );
}
