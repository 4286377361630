import React from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";

ChartJS.register(ArcElement, Tooltip, Legend);


export default function Orders({dash}) {
   const data = {
    labels: [`Completed ${dash?.completedOrderPercentage || 0}%`, `Pending ${dash?.pendingOrderPercentage || 0}%`],
    datasets: [
      {
        label: "# of Votes",
        data: [ dash?.completedOrderPercentage || 0, dash?.pendingOrderPercentage || 0],
        backgroundColor: ["#1BA97D", "#087D59", "#DCF9E8"],
  
        borderWidth: 1,
      },
    ],
  };
  

  return <Doughnut className="gender-chart" data={data} />;
}
