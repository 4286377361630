import React, { useState } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { Formik } from "formik";
import { forgotPassword } from "../redux/actions/adminActions";

export default function ForgotPassword() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [validateValue, setValidateValue] = useState(false);


  return (
    <div>
      <Container fluid>
        <Row>
          <Col lg={12}>
            <div className="logo">
              <img src={require("../Assets/Images/Logo.svg").default} />
            </div>
          </Col>
        </Row>
      </Container>
      <Container>
        <Row className="box-center">
          <Col lg={12} className="m-auto">
            <div className="login-cmn-box">
              <div className="login-box-inner-wrap">
                <h2 className="text-center">Forgot Password</h2>
                <Formik
                  initialValues={{
                    email: ""
                  }}
                  validate={(values) => {
                    const errors = {};


                    if (!values.email) {
                      errors.email = "Required";
                    } else if (
                      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
                    ) {
                      errors.email = "Invalid email address";
                    }

                    return errors;
                  }}
                  validateOnChange={validateValue}
                  onSubmit={(values, { setSubmitting, resetForm }) => {
                    setValidateValue(true)
                    dispatch(forgotPassword(values)).then((data) => {
                      console.log(data, "data");
                      if (data?.payload?.success) {
                        resetForm();
                        toast.success(data?.payload?.message);
                         navigate(`/otp/${data?.payload?.data?._id}`)
                      } else {
                        toast.error(data?.payload?.message);
                      }
                    });
                    setSubmitting(false);
                  }}
                >
                  {({
                    values,
                    errors,
                    handleChange,
                    handleSubmit,
                    isSubmitting,
                    setFieldValue
                  }) => (

                    <Form onSubmit={handleSubmit}>
                      <div className="form-set">
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                          <Form.Label>Email</Form.Label>
                          <Form.Control
                            type="email"
                            placeholder="Type Here"
                            name="email"
                            onChange={handleChange}
                            value={values.email}
                          />
                          {errors.email && (
                            <span className="formik-errors">{errors.email}</span>
                          )}
                        </Form.Group>
                      </div>
                      <button
                        variant="primary"
                        type="submit"
                        className="submit"
                      >
                        Send OTP
                      </button>
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
