import React, { useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";
import Layout from "../Components/Layout/Layout";
import { Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { getOrderDetails } from "../redux/actions/adminActions";
import moment from "moment";

export default function ViewOrders() {

  const BASE_URL = process.env.REACT_APP_HOST_NAME;
  const navigate = useNavigate();
  const { id } = useParams();
  const dispatch = useDispatch();
  const { orderDetails } = useSelector((state) => ({
    orderDetails: state.userMgmtSlice?.orderDetails
  }));

  useEffect(() => {
    dispatch(
      getOrderDetails(id)
    );
  }, [id]);

  console.log(orderDetails, "orderDetails");

  return (
    <Layout>
      <div className="right-top">
        <div className="heading-top-inner">
          <div>
            <h2>View Orders</h2>
            <p>USER #{orderDetails?.orderId || 'N/A'}</p>
          </div>
        </div>
        <hr />
      </div>

      <Container fluid>
        <div className="product-cmn-tab">
          <Row>
            <Col lg={4}>
              <div className="product-tab-left">
                <Link to="/admin/ManageOrders">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="40"
                    height="42"
                    viewBox="0 0 40 42"
                    fill="none"
                  >
                    <path
                      d="M10 21L8.91379 22.0345L7.92857 21L8.91379 19.9655L10 21ZM30 19.5C30.8284 19.5 31.5 20.1716 31.5 21C31.5 21.8284 30.8284 22.5 30 22.5V19.5ZM15.5805 29.0345L8.91379 22.0345L11.0862 19.9655L17.7529 26.9655L15.5805 29.0345ZM8.91379 19.9655L15.5805 12.9655L17.7529 15.0345L11.0862 22.0345L8.91379 19.9655ZM10 19.5H30V22.5L10 22.5L10 19.5Z"
                      fill="#40413A"
                    />
                  </svg>{" "}
                  Back to previous page
                </Link>
              </div>
            </Col>
          </Row>
        </div>
      </Container>

      <Container fluid>
        <Row>
          <Col lg={8}>
            <Row>
              <Col lg={12}>
                <div className="customer-form">
                  <h2 className="text-uppercase">Assign Orders</h2>
                  <hr />
                  <Row>
                    <Col lg={4}>
                      <div className="member-info-inner">
                        <h2>Service Type</h2>
                        <div className="member-inner">
                          <p>{orderDetails?.service_id?.service_name || 'N/A'}</p>
                        </div>
                      </div>
                    </Col>
                    <Col lg={4}>
                      <div className="member-info-inner">
                        <h2>Order Id#</h2>
                        <div className="member-inner">
                          <p>{orderDetails?.orderId || 'N/A'}</p>
                        </div>
                      </div>
                    </Col>
                    <Col lg={4}>
                      <div className="member-info-inner">
                        <h2>Start date</h2>
                        <div className="member-inner">
                          <p> {moment(orderDetails?.start_date).format("DD/MM/YYYY") ||
                            "N/A"}</p>
                        </div>
                      </div>
                    </Col>{" "}
                    <Col lg={4} className="mt-4">
                      <div className="member-info-inner">
                        <h2>Frequency</h2>
                        <div className="member-inner">
                          <p>{orderDetails?.frequency}</p>
                        </div>
                      </div>
                    </Col>
                    <Col lg={4} className="mt-4">
                      <div className="member-info-inner">
                        <h2>Slot</h2>
                        <div className="member-inner">
                          <p>{orderDetails?.slot_name}</p>
                        </div>
                      </div>
                    </Col>
                    <Col lg={4} className="mt-4">
                      <div className="member-info-inner">
                        <h2>Payment</h2>
                        <div className="member-inner">
                          <p>Debit Card</p>
                        </div>
                      </div>
                    </Col>
                    <Col lg={6} className="mt-4">
                      <div className="member-info-inner">
                        <h2>Address</h2>
                        <div className="member-inner">
                          <p>{orderDetails?.address}, {orderDetails?.city}</p>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
              </Col>

              <Col lg={12}>
                <div className="profile-products-sec">
                  <h2 className="text-uppercase">Instructions </h2>
                  <hr />
                  <div className="admin-box-main">
                    <div className="admin-box-inner">
                      <p className="text-start">
                        {orderDetails?.instructions}
                      </p>
                    </div>
                  </div>
                </div>
              </Col>

              <Col lg={12}>
                <div className="profile-products-sec ">
                  <h2 className="text-uppercase">Assigned Helper </h2>
                  <hr />
                  <Row>
                    <Col lg={4}>
                      <div className="property-name-show view mx-5 d-block text-center">
                        <img src={
                          orderDetails?.vendorId?.profile_image ? BASE_URL + orderDetails?.vendorId?.profile_image :
                            require("../Assets/Images/user.png")
                        } />

                        <h2 className="float-left mt-2">{orderDetails?.vendorId?.first_name + " " + orderDetails?.vendorId?.last_name}</h2>
                      </div>
                    </Col>
                    <Col lg={8}>
                      <div className="d-flex px-5">
                        <div className="status-box">
                          <h2>Status</h2>
                          <p>{
                            orderDetails?.orderStatus == 0 ? 'Submitted/In Queue' :
                              orderDetails?.orderStatus == 1 ? 'Pending' :
                                orderDetails?.orderStatus == 2 ? 'Ongoing' :
                                  orderDetails?.orderStatus == 3 ? 'Completed' :
                                    orderDetails?.orderStatus == 4 ? 'Rejected' :
                                      orderDetails?.orderStatus == 5 && 'Approved'
                          }</p>
                        </div>
                        <div className="status-box">
                          <h2>Start Time</h2>
                          <p>{orderDetails?.orderDet?.start_time || 'N/A'}</p>
                        </div>
                        <div className="status-box">
                          <h2>End Time</h2>
                          <p>{orderDetails?.orderDet?.end_time || 'N/A'}</p>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
          </Col>
          <Col lg={4}>
            <div className="product-overview-right">
              <div className="product-overview-box">
                <h2>STATUS</h2>
                <p>{
                  orderDetails?.orderStatus == 0 ? 'Submitted/In Queue' :
                    orderDetails?.orderStatus == 1 ? 'Pending' :
                      orderDetails?.orderStatus == 2 ? 'Ongoing' :
                        orderDetails?.orderStatus == 3 ? 'Completed' :
                          orderDetails?.orderStatus == 4 ? 'Rejected' :
                            orderDetails?.orderStatus == 5 && 'Approved'
                }</p>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </Layout>
  );
}
