import React, { useState } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useFormik } from "formik";
import { resetPassword } from "../redux/actions/adminActions";
import { toast } from "react-toastify";

export default function ResetPassword() {

  const { token } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [validateValue, setValidateValue] = useState(false);

  const validate = (values) => {
    const errors = {};

    if (!values.password) {
      errors.password = "Required";
    } else if (values.password.length <= 5) {
      errors.password = "Password should be 6+ characters";
    }
    if (!values.confirmPassword) {
      errors.confirmPassword = "Required";
    } else if (values.password !== values.confirmPassword) {
      errors.confirmPassword = "Passwords doesn't match";
    }
    return errors;
  };

  const formik = useFormik({
    initialValues: {
      password: "",
      confirmPassword: "",
    },
    validate,
    validateOnChange: validateValue,
    onSubmit: async (values) => {
      setValidateValue(true);

      dispatch(resetPassword({
        password: values.password
      })).then((data) => {
        if (data?.payload?.success) {
          toast.success(data?.payload?.message);
          navigate("/");
          sessionStorage.clear();
        } else {
          toast.error(data?.payload?.message);
        }
      });
    },
  });
  return (
    <div>
      <Container fluid>
        <Row>
          <Col lg={12}>
            <div className="logo">
              <img src={require("../Assets/Images/Logo.svg").default} />
            </div>
          </Col>
        </Row>
      </Container>
      <Container>
        <Row className="box-center">
          <Col lg={12} className="m-auto">
            <div className="login-cmn-box">
              <div className="login-box-inner-wrap">
                <h2 className="text-center">Reset Password</h2>
                <Form onSubmit={formik.handleSubmit} >
                  <div className="form-set">

                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>Password</Form.Label>
                      <Form.Control
                        type="password"
                        placeholder="Enter Password *"
                        name="password"
                        value={formik.values.password}
                        onChange={formik.handleChange}
                      />
                      {formik.errors.password && (
                        <p className="formik-errors">
                          {formik.errors.password}
                        </p>
                      )}

                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>Confirm Password</Form.Label>
                      <Form.Control
                        type="password"
                        className="form-control"
                        placeholder="Re Enter Password *"
                        name="confirmPassword"
                        value={formik.values.confirmPassword}
                        onChange={formik.handleChange}
                      />
                      {formik.errors.confirmPassword && (
                        <p className="formik-errors">
                          {formik.errors.confirmPassword}
                        </p>
                      )}
                    </Form.Group>
                  </div>
                  <button
                    to="/"
                    variant="primary"
                    type="submit"
                    className="submit"
                  >
                    Send OTP
                  </button>
                </Form>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
