import React, { useState } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Formik } from "formik";
import { toast } from "react-toastify";
import { verifyOtp } from "../redux/actions/adminActions";

export default function LoginOtp() {
  const {id} = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [validateValue, setValidateValue] = useState(false);

  return (
    <div>
      <Container fluid>
        <Row>
          <Col lg={12}>
            <div className="logo">
              <img src={require("../Assets/Images/Logo.svg").default} />
            </div>
          </Col>
        </Row>
      </Container>
      <Container>
        <Row className="box-center">
          <Col lg={12} className="m-auto">
            <div className="login-cmn-box">
              <div className="login-box-inner-wrap">
                <h2 className="text-center">OTP</h2>
                <Formik
                  initialValues={{
                    otp: ""
                  }}
                  validate={(values) => {
                    const errors = {};


                    if (!values.otp) {
                      errors.otp = "Required";
                    }

                    return errors;
                  }}
                  validateOnChange={validateValue}
                  onSubmit={(values, { setSubmitting, resetForm }) => {
                    values.user_id = id;
                    setValidateValue(true)
                    dispatch(verifyOtp(values)).then((data) => {
                      console.log(data, "data");
                      if (data?.payload?.success) {
                        sessionStorage.setItem("token",data?.payload?.data?.token)
                        resetForm();
                        toast.success(data?.payload?.message);
                        navigate(`/resetPassword`)
                      } else {
                        toast.error(data?.payload?.message);
                      }
                    });
                    setSubmitting(false);
                  }}
                >
                  {({
                    values,
                    errors,
                    handleChange,
                    handleSubmit,
                    isSubmitting,
                    setFieldValue
                  }) => (

                    <Form onSubmit={handleSubmit}>
                      <div className="form-set">
                        <Form.Group className="mb-3" controlId="formBasicPassword">
                          <Form.Label>OTP</Form.Label>
                          <Form.Control
                            type="number"
                            placeholder="Enter OTP *"
                            name="otp"
                            onChange={handleChange}
                            value={values.otp}
                          />
                           {errors.otp && (
                            <span className="formik-errors">{errors.otp}</span>
                          )}
                        </Form.Group>
                      </div>
                      <button
                        variant="primary"
                        type="submit"
                        className="submit"
                      >
                        Verify OTP
                      </button>
                    </Form>
                  )}
                </Formik>

              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
