import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import Cookies from "universal-cookie";
import { login } from "../redux/actions/adminAuth";
import { Formik } from "formik";

export default function Login() {
  const [keepMeLoggedIn, setKeepMeLoggedIn] = useState(false);
  const [fcmtoken, setFCMToken] = useState();
  const dispatch = useDispatch();
  const cookies = new Cookies();

  useEffect(() => {
    if (cookies.get("token")) {
      sessionStorage.setItem("token", cookies.get("token"));
      window.location.href = "/admin/dashboard";
    }
  }, []);

  return (
    <div>
      <Container fluid>
        <Row>
          <Col lg={12}>
            <div className="logo">
              <img src={require("../Assets/Images/Logo.svg").default} />
            </div>
          </Col>
        </Row>
      </Container>
      <Container>
        <Row className="box-center">
          <Col lg={12} className="m-auto">
            <div className="login-cmn-box">
              <div className="login-box-inner-wrap">
                <h2 className="text-center">Log in</h2>
                <Formik
                  initialValues={{ email: "", password: "" }}
                  validate={(values) => {
                    const errors = {};
                    if (!values.email) {
                      errors.email = "Required*";
                    } else if (
                      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(
                        values.email
                      )
                    ) {
                      errors.email = "Invalid email address";
                    }

                    if (!values.password) {
                      errors.password = "Required*";
                    } else if (values.password < 8) {
                      errors.password = "Password must be of 8 characters";
                    }

                    return errors;
                  }}
                  onSubmit={(values, { setSubmitting }) => {

                     dispatch(login({ loginObj: values, keepMeLoggedIn }));

                    setSubmitting(false);
                  }}
                >
                  {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,
                  }) => (
                    <Form onSubmit={handleSubmit}>
                      <div className="form-set">
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                          <Form.Label>Email</Form.Label>
                          <Form.Control
                            type="email"
                            placeholder="Type Here"
                            name="email"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.email}
                          />
                          <span className="formik-errors">
                            {errors.email && touched.email && errors.email}
                          </span>
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="formBasicPassword">
                          <Form.Label>Password</Form.Label>
                          <Form.Control
                            type="password"
                            placeholder="Type Here"
                            name="password"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.password}
                          />
                          <span className="formik-errors">
                            {errors.password &&
                              touched.password &&
                              errors.password}
                          </span>
                        </Form.Group>
                      </div>
                      {/* <Link
                        to="/Dashboard"
                        variant="primary"
                        type="submit"
                        className="submit"
                      >
                        Log In
                      </Link> */}
                      <Button
                        disabled={isSubmitting}
                        className="submit"
                        variant="primary"
                        type="submit"
                      >
                        Sign In
                      </Button>
                      <Link to="/Forgot-Password" className="forgot">
                        Forgot Password?
                      </Link>
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
