import React, { useState } from "react";
import { Link } from "react-router-dom";

export default function Sidebar({ isActive, setIsActive, setShow, show,adminDetails }) {
  return (
    <aside>
      <div className="sidebar">
        <div className="toggle-icon" onClick={() => setShow(!show)}>
          <img src={require("../../Assets/Images/toggle.svg").default} />
        </div>
        <div className="logo-side">
          <img
            className="small"
            src={require("../../Assets/Images/C.svg").default}
          />
          <img
            className="big"
            src={require("../../Assets/Images/Logo.svg").default}
          />
        </div>
        <div className="side-menu">
          <div>
            
          {
              (adminDetails?.role == 'superadmin' || adminDetails?.access_permissions?.dashboard) &&   <div className="side-btm-space">
              <Link
                className={
                  window.location.pathname == "/admin/Dashboard"
                    ? "menu-btn bar_active"
                    : "menu-btn "
                }
                to="/admin/Dashboard"
              >
                <svg
                  className="dash-only"
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M9.17323 5.48819C9.17323 6.94094 9.17323 8.39764 9.17323 9.85039C9.17323 10.6693 8.85039 10.9882 8.03937 10.9882C5.73622 10.9882 3.42913 10.9882 1.12598 10.9882C0.34252 10.9882 0 10.6457 0 9.86614C0 6.94488 0 4.02756 0 1.1063C0 0.350394 0.350394 0 1.11024 0C3.4252 0 5.74016 0 8.05906 0C8.84252 0 9.17323 0.330709 9.17323 1.12598C9.17717 2.57874 9.17323 4.03543 9.17323 5.48819ZM1.85039 1.84252C1.85039 4.29921 1.85039 6.71654 1.85039 9.12992C3.69685 9.12992 5.51969 9.12992 7.33858 9.12992C7.33858 6.68504 7.33858 4.26772 7.33858 1.84252C5.50394 1.84252 3.68898 1.84252 1.85039 1.84252Z"
                    fill="black"
                  />
                  <path
                    d="M10.8228 14.5118C10.8228 13.059 10.8228 11.6023 10.8228 10.1496C10.8228 9.33068 11.1456 9.01178 11.9606 9.01178C14.2638 9.01178 16.5708 9.01178 18.874 9.01178C19.6535 9.01178 19.996 9.3543 19.996 10.1378C19.996 13.0472 19.996 15.9527 19.996 18.8622C19.996 19.6653 19.6575 20 18.8504 20C16.559 20 14.2638 20 11.9724 20C11.1378 20 10.8228 19.6811 10.8228 18.8386C10.8228 17.3937 10.8228 15.9527 10.8228 14.5118ZM18.1496 18.1574C18.1496 15.7008 18.1496 13.2874 18.1496 10.8661C16.3031 10.8661 14.4803 10.8661 12.6575 10.8661C12.6575 13.311 12.6575 15.7244 12.6575 18.1574C14.496 18.1574 16.311 18.1574 18.1496 18.1574Z"
                    fill="black"
                  />
                  <path
                    d="M15.3937 7.32677C14.248 7.32677 13.0984 7.33071 11.9528 7.32677C11.1417 7.32284 10.8268 7.01575 10.8268 6.21654C10.8228 4.51181 10.8228 2.81102 10.8268 1.1063C10.8268 0.334646 11.1575 0.00393701 11.9252 0C14.2441 0 16.563 0 18.8819 0C19.6614 0 19.9961 0.34252 20 1.13386C20 2.82677 20 4.51575 20 6.20866C20 6.99606 19.6693 7.32284 18.874 7.32677C17.7126 7.33071 16.5512 7.32677 15.3937 7.32677ZM18.1496 1.85827C16.2992 1.85827 14.4724 1.85827 12.6575 1.85827C12.6575 3.09055 12.6575 4.29134 12.6575 5.5C14.5 5.5 16.315 5.5 18.1496 5.5C18.1496 4.28346 18.1496 3.08268 18.1496 1.85827Z"
                    fill="black"
                  />
                  <path
                    d="M4.59449 12.6732C5.75197 12.6732 6.91339 12.6693 8.07087 12.6732C8.85433 12.6772 9.17323 12.9882 9.17323 13.7717C9.17717 15.4764 9.17717 17.1772 9.17323 18.8819C9.17323 19.6732 8.84252 20 8.04725 20C5.74016 20 3.43307 20 1.12598 20C0.34252 20 0 19.6575 0 18.878C0 17.1732 0 15.4724 0 13.7677C0 13.0197 0.334646 12.6811 1.07874 12.6772C2.25197 12.6693 3.42126 12.6732 4.59449 12.6732ZM7.34252 18.1575C7.34252 16.9173 7.34252 15.7165 7.34252 14.5118C5.49606 14.5118 3.67323 14.5118 1.85039 14.5118C1.85039 15.7402 1.85039 16.9409 1.85039 18.1575C3.68504 18.1575 5.50394 18.1575 7.34252 18.1575Z"
                    fill="black"
                  />
                </svg>
                <p> Dashboard</p>
              </Link>
            </div> || ''
}
{
              (adminDetails?.role == 'superadmin' || adminDetails?.access_permissions?.user_mngmnt) &&  <div>
              <Link
                to="/admin/UserManagement"
                className={
                  window.location.pathname == "/admin/UserManagement"
                    ? "menu-btn bar_active"
                    : "menu-btn "
                }
              >
                <svg
                  width="21"
                  height="21"
                  viewBox="0 0 21 21"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M18 20V18C18 16.9391 17.6049 15.9217 16.9016 15.1716C16.1984 14.4214 15.2446 14 14.25 14H6.75C5.75544 14 4.80161 14.4214 4.09835 15.1716C3.39509 15.9217 3 16.9391 3 18V20"
                    stroke="black"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M10.5 10C12.433 10 14 8.433 14 6.5C14 4.567 12.433 3 10.5 3C8.567 3 7 4.567 7 6.5C7 8.433 8.567 10 10.5 10Z"
                    stroke="black"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
                <p>User Management</p>
              </Link>
            </div> || ''
}
{
              (adminDetails?.role == 'superadmin' || adminDetails?.access_permissions?.account_activation) &&  <div>
              <Link
                className={
                  window.location.pathname == "/admin/AccountActivation"
                    ? "menu-btn bar_active"
                    : "menu-btn "
                }
                to="/admin/AccountActivation"
              >
                <svg
                  width="21"
                  height="21"
                  viewBox="0 0 21 21"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clip-path="url(#clip0_2137_2964)">
                    <path
                      d="M14 18.375V16.625C14 15.6967 13.6313 14.8065 12.9749 14.1501C12.3185 13.4937 11.4283 13.125 10.5 13.125H4.375C3.44674 13.125 2.5565 13.4937 1.90013 14.1501C1.24375 14.8065 0.875 15.6967 0.875 16.625V18.375"
                      stroke="black"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M7.4375 9.625C9.3705 9.625 10.9375 8.058 10.9375 6.125C10.9375 4.192 9.3705 2.625 7.4375 2.625C5.5045 2.625 3.9375 4.192 3.9375 6.125C3.9375 8.058 5.5045 9.625 7.4375 9.625Z"
                      stroke="black"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M14.875 9.625L16.625 11.375L20.125 7.875"
                      stroke="black"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_2137_2964">
                      <rect width="21" height="21" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
                <p>Account Activation</p>
              </Link>
            </div> || ''
}
{
              (adminDetails?.role == 'superadmin' || adminDetails?.access_permissions?.manage_orders) &&    <div>
              <Link
                className={
                  window.location.pathname == "/admin/ManageOrders"
                    ? "menu-btn bar_active"
                    : "menu-btn "
                }
                to="/admin/ManageOrders"
              >
                <svg
                  width="21"
                  height="21"
                  viewBox="0 0 21 21"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clip-path="url(#clip0_2137_2970)">
                    <path
                      d="M18.375 7V18.375H2.625V7"
                      stroke="black"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M20.125 2.625H0.875V7H20.125V2.625Z"
                      stroke="black"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M8.75 10.5H12.25"
                      stroke="black"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_2137_2970">
                      <rect width="21" height="21" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
                <p>Manage Orders</p>
              </Link>
            </div> || ''
}
{
              (adminDetails?.role == 'superadmin' || adminDetails?.access_permissions?.manage_properties) &&    <div className="side-btm-space">
              <Link
                className={
                  window.location.pathname == "/admin/ManageProperties"
                    ? "menu-btn bar_active"
                    : "menu-btn "
                }
                to="/admin/ManageProperties"
              >
                <svg
                  width="21"
                  height="21"
                  viewBox="0 0 21 21"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M2.625 7.875L10.5 1.75L18.375 7.875V17.5C18.375 17.9641 18.1906 18.4092 17.8624 18.7374C17.5342 19.0656 17.0891 19.25 16.625 19.25H4.375C3.91087 19.25 3.46575 19.0656 3.13756 18.7374C2.80937 18.4092 2.625 17.9641 2.625 17.5V7.875Z"
                    stroke="black"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M7.875 19.25V10.5H13.125V19.25"
                    stroke="black"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
                <p> Manage Properties</p>
              </Link>
            </div> || ''
}
{
              (adminDetails?.role == 'superadmin' || adminDetails?.access_permissions?.manage_services) &&    <div className="side-btm-space">
              <Link
                className={
                  window.location.pathname == "/admin/ManageServices"
                    ? "menu-btn bar_active"
                    : "menu-btn "
                }
                to="/admin/ManageServices"
              >
                <svg
                  width="20"
                  height="19"
                  viewBox="0 0 20 19"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M11.8625 5.0125C11.7022 5.17606 11.6124 5.39596 11.6124 5.625C11.6124 5.85403 11.7022 6.07393 11.8625 6.2375L13.2625 7.6375C13.4261 7.79782 13.646 7.88762 13.875 7.88762C14.104 7.88762 14.3239 7.79782 14.4875 7.6375L17.7862 4.33875C18.2262 5.31104 18.3594 6.39433 18.1681 7.44425C17.9768 8.49417 17.4701 9.46086 16.7155 10.2155C15.9609 10.9701 14.9942 11.4769 13.9442 11.6681C12.8943 11.8594 11.811 11.7262 10.8387 11.2862L4.7925 17.3325C4.4444 17.6806 3.97228 17.8762 3.48 17.8762C2.98771 17.8762 2.51559 17.6806 2.1675 17.3325C1.8194 16.9844 1.62384 16.5123 1.62384 16.02C1.62384 15.5277 1.8194 15.0556 2.1675 14.7075L8.21375 8.66125C7.77376 7.68896 7.64055 6.60567 7.83184 5.55575C8.02314 4.50582 8.52987 3.53913 9.2845 2.7845C10.0391 2.02987 11.0058 1.52315 12.0557 1.33185C13.1057 1.14055 14.189 1.27377 15.1612 1.71375L11.8712 5.00375L11.8625 5.0125Z"
                    stroke="black"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
                <p>Manage Services</p>
              </Link>
            </div> || ''
}
{
              (adminDetails?.role == 'superadmin' || adminDetails?.access_permissions?.manage_faqs) && 
            <div className="side-btm-space">
              <Link
                className={
                  window.location.pathname == "/admin/ManageFaqs"
                    ? "menu-btn bar_active"
                    : "menu-btn "
                }
                to="/admin/ManageFaqs"
              >
                <svg
                  width="21"
                  height="21"
                  viewBox="0 0 21 21"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clip-path="url(#clip0_2137_3022)">
                    <path
                      d="M10.5 19.25C15.3325 19.25 19.25 15.3325 19.25 10.5C19.25 5.66751 15.3325 1.75 10.5 1.75C5.66751 1.75 1.75 5.66751 1.75 10.5C1.75 15.3325 5.66751 19.25 10.5 19.25Z"
                      stroke="black"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M7.95374 7.87501C8.15945 7.29022 8.56549 6.7971 9.09995 6.483C9.6344 6.1689 10.2628 6.05408 10.8738 6.15888C11.4848 6.26369 12.039 6.58134 12.4382 7.0556C12.8374 7.52985 13.0559 8.13009 13.055 8.75001C13.055 10.5 10.43 11.375 10.43 11.375"
                      stroke="black"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M10.5 14.875H10.5075"
                      stroke="black"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_2137_3022">
                      <rect width="21" height="21" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
                <p> Manage FAQs</p>
              </Link>
            </div> || ''
}
{
              (adminDetails?.role == 'superadmin' || adminDetails?.access_permissions?.notification) && 
            <div>
              <Link
                className={
                  window.location.pathname == "/admin/Notification"
                    ? "menu-btn bar_active"
                    : "menu-btn "
                }
                to="/admin/Notification"
              >
                <svg
                  width="21"
                  height="21"
                  viewBox="0 0 21 21"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M15.75 7C15.75 5.60761 15.1969 4.27226 14.2123 3.28769C13.2277 2.30312 11.8924 1.75 10.5 1.75C9.10761 1.75 7.77226 2.30312 6.78769 3.28769C5.80312 4.27226 5.25 5.60761 5.25 7C5.25 13.125 2.625 14.875 2.625 14.875H18.375C18.375 14.875 15.75 13.125 15.75 7Z"
                    stroke="black"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M12.0137 18.375C11.8599 18.6402 11.6391 18.8603 11.3734 19.0133C11.1078 19.1664 10.8066 19.2469 10.5 19.2469C10.1934 19.2469 9.8922 19.1664 9.62654 19.0133C9.36087 18.8603 9.14007 18.6402 8.98624 18.375"
                    stroke="black"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
                <p>Notification</p>
              </Link>
            </div> || ''
}
{
              (adminDetails?.role == 'superadmin' || adminDetails?.access_permissions?.manage_admins) && 
            <div className="side-btm-space">
              <Link
                className={
                  window.location.pathname == "/admin/Administrators"
                    ? "menu-btn bar_active"
                    : "menu-btn "
                }
                to="/admin/Administrators"
              >
                <svg
                  className="dash-only"
                  width="21"
                  height="21"
                  viewBox="0 0 21 21"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clip-path="url(#clip0_2137_3016)">
                    <path
                      d="M12.7916 9.94758C14.3345 10.6022 15.6161 11.5808 16.6225 12.8936C16.1743 13.1118 15.741 13.3232 15.3192 13.5278C12.6231 10.7818 9.44562 9.89871 5.89098 11.5695C2.55414 13.1368 1.2233 15.9805 1.46746 19.6005C1.44109 19.605 1.39983 19.6164 1.35741 19.6164C0.93214 19.6175 0.506867 19.6175 0.0850333 19.6175C-0.42965 16.1328 1.40556 11.7604 5.73165 9.9419C4.12455 8.52573 3.42532 6.76746 3.75086 4.66139C3.97554 3.20885 4.71374 2.02909 5.88984 1.13234C8.16751 -0.604339 11.3897 -0.309967 13.3717 1.79837C15.4338 3.98968 15.4361 7.80401 12.7916 9.94758ZM13.5115 5.59224C13.5 3.26341 11.6041 1.39489 9.26222 1.40398C6.91462 1.41194 5.02554 3.29637 5.03471 5.61497C5.04502 7.94153 6.94442 9.81347 9.28514 9.80437C11.6304 9.79528 13.523 7.90857 13.5115 5.59224Z"
                      fill="black"
                    />
                    <path
                      d="M19.5018 19.6229C19.1419 19.8798 18.7521 20.0434 18.336 20.1469C18.2902 20.1582 18.1962 20.1275 18.1801 20.0912C18.0426 19.7752 17.8042 19.6013 17.4614 19.6104C17.1061 19.6206 16.8825 19.8298 16.761 20.1719C16.3071 20.0957 15.9025 19.9195 15.5276 19.6718C15.5001 19.6536 15.4875 19.5843 15.4955 19.5445C15.6549 18.8068 15.2067 18.3806 14.4673 18.6034C14.2266 18.2602 14.081 17.8737 13.9859 17.4486C14.3435 17.3248 14.5762 17.0974 14.5705 16.7121C14.5659 16.3371 14.3389 16.1143 13.9515 15.9859C14.0902 15.6267 14.2254 15.2778 14.3676 14.913C14.763 15.0994 15.1069 15.0982 15.3809 14.7766C15.6331 14.4799 15.5964 14.1605 15.3648 13.7946C15.8257 13.5934 16.2704 13.4002 16.7324 13.199C16.8608 13.607 17.0843 13.8446 17.4717 13.8503C17.8706 13.8571 18.101 13.6184 18.2214 13.2536C18.7212 13.357 19.1625 13.5502 19.5694 13.8503C19.3654 14.1799 19.3436 14.4992 19.6015 14.7902C19.8663 15.088 20.1965 15.0994 20.5873 14.9186C20.7272 15.2812 20.8613 15.6279 21 15.987C20.6022 16.1314 20.3844 16.37 20.3993 16.7542C20.4131 17.0963 20.616 17.3145 20.9989 17.4498C20.8464 17.8339 20.6962 18.2124 20.5507 18.5784C19.6233 18.5 19.2519 18.8773 19.5018 19.6229ZM17.5164 17.9465C18.1847 17.9419 18.7177 17.4134 18.7212 16.7508C18.7235 16.0802 18.1801 15.5426 17.5027 15.546C16.8332 15.5506 16.3014 16.0791 16.2979 16.7417C16.2956 17.4134 16.8401 17.951 17.5164 17.9465Z"
                      fill="black"
                    />
                    <path
                      d="M9.90279 12.2181C10.1527 12.2181 10.4037 12.217 10.6536 12.2181C11.1683 12.2204 11.4102 12.4193 11.4904 12.9251C11.7048 14.2787 11.9008 15.6347 12.1312 16.9861C12.2011 17.3941 12.122 17.7169 11.8744 18.0442C11.4377 18.6216 11.0365 19.224 10.6169 19.815C10.2673 20.3071 9.74575 20.3196 9.37206 19.8479C8.88145 19.2285 8.40116 18.6 7.90023 17.9897C7.67556 17.7157 7.59073 17.4418 7.65148 17.0838C7.88762 15.6824 8.09739 14.2765 8.31977 12.8728C8.38855 12.4364 8.63958 12.2261 9.08778 12.2204C9.35831 12.2147 9.63112 12.2181 9.90279 12.2181ZM9.61966 13.6343C9.61049 13.6718 9.60247 13.6991 9.59788 13.7275C9.42365 14.8288 9.24826 15.9302 9.07861 17.0327C9.0683 17.0997 9.066 17.1918 9.10154 17.2395C9.36862 17.5941 9.64717 17.9408 9.93718 18.3079C10.1756 17.9703 10.406 17.6578 10.6169 17.335C10.6697 17.2543 10.6949 17.1315 10.68 17.0361C10.5333 16.0484 10.3728 15.063 10.2238 14.0753C10.1492 13.5832 10.1561 13.582 9.61966 13.6343Z"
                      fill="black"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_2137_3016">
                      <rect width="21" height="20.1923" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
                <p>Manage Administrator</p>
              </Link>
            </div> || ''
}
{
              (adminDetails?.role == 'superadmin' || adminDetails?.access_permissions?.support) && 
            <div className="side-btm-space">
              <Link
                className={
                  window.location.pathname == "/admin/Support"
                    ? "menu-btn bar_active"
                    : "menu-btn "
                }
                to="/admin/Support"
              >
                <svg
                 className="dash-only"
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                >
                  <path
                    d="M10 0C4.486 0 0 4.486 0 10V14.143C0 15.167 0.897 16 2 16H3C3.26522 16 3.51957 15.8946 3.70711 15.7071C3.89464 15.5196 4 15.2652 4 15V9.857C4 9.59178 3.89464 9.33743 3.70711 9.14989C3.51957 8.96236 3.26522 8.857 3 8.857H2.092C2.648 4.987 5.978 2 10 2C14.022 2 17.352 4.987 17.908 8.857H17C16.7348 8.857 16.4804 8.96236 16.2929 9.14989C16.1054 9.33743 16 9.59178 16 9.857V16C16 17.103 15.103 18 14 18H12V17H8V20H14C16.206 20 18 18.206 18 16C19.103 16 20 15.167 20 14.143V10C20 4.486 15.514 0 10 0Z"
                    fill="black"
                  />
                </svg>
                <p>Support</p>
              </Link>
            </div> || ''
}
          </div>
          <div>
            <div className="side-btm-last">
              <div>
                <Link
                  className={
                    window.location.pathname == "/admin/ChangePassword"
                      ? "menu-btn bar_active"
                      : "menu-btn "
                  }
                  to="/admin/ChangePassword"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="22"
                    viewBox="0 0 20 22"
                    fill="none"
                  >
                    <path
                      d="M17 10H3C1.89543 10 1 10.8954 1 12V19C1 20.1046 1.89543 21 3 21H17C18.1046 21 19 20.1046 19 19V12C19 10.8954 18.1046 10 17 10Z"
                      stroke="#5F6368"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M5 10V6C5 4.67392 5.52678 3.40215 6.46447 2.46447C7.40215 1.52678 8.67392 1 10 1C11.3261 1 12.5979 1.52678 13.5355 2.46447C14.4732 3.40215 15 4.67392 15 6V10"
                      stroke="#5F6368"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                  <p>Change Password</p>
                </Link>
              </div>
              <div>
                <Link
                  className={
                    window.location.pathname == "/"
                      ? "menu-btn bar_active"
                      : "menu-btn "
                  }
                  to="/"
                  onClick={()=>{
                    sessionStorage.clear();
                  }}
                >
                  <svg
                    width="21"
                    height="21"
                    viewBox="0 0 21 21"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M7.875 18.375H4.375C3.91087 18.375 3.46575 18.1906 3.13756 17.8624C2.80937 17.5342 2.625 17.0891 2.625 16.625V4.375C2.625 3.91087 2.80937 3.46575 3.13756 3.13756C3.46575 2.80937 3.91087 2.625 4.375 2.625H7.875"
                      stroke="black"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M14 14.875L18.375 10.5L14 6.125"
                      stroke="black"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M18.375 10.5H7.875"
                      stroke="black"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                  <p>Logout</p>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </aside>
  );
}
