import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";
import Layout from "../Components/Layout/Layout";
import { useDispatch, useSelector } from "react-redux";
import {
  getUsers,
  tempBannedAccount,
  tempBannedAccountCustomer,
} from "../redux/actions/adminActions";
import Pagination from "../Components/Layout/elements/Pagination";
import DownloadCsv from "../Components/Layout/elements/DownloadCsv";

export default function CustomerManagement() {
  const dispatch = useDispatch();
  const BASE_URL = process.env.REACT_APP_HOST_NAME;
  const { users, totalUsers, dash } = useSelector((state) => ({
    users: state.userMgmtSlice.users,
    totalUsers: state.userMgmtSlice.totalUsers,
    dash: state.dashboardSlice.dashboard,
  }));

  const [search, setSearch] = useState("");
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(5);

  useEffect(() => {
    dispatch(
      getUsers({
        page: search ? 1 : page,
        limit: limit,
        search: search,
        user_type: 0,
      })
    );
  }, [page, limit, search]);

  const handleTempBan = (data, id) => {
    dispatch(tempBannedAccountCustomer({ id: id, status: data }));
  };
  const data = users?.map((item, i) => {
    return {
      "S.No.": i + 1,
      Firstname: item?.first_name,
      Lastname: item?.last_name,
      Email: item?.email,
      "Phone Number": item?.phone_number,
      Location: item?.city || "-",
      Position:
        item?.user_type == 0
          ? "Customer"
          : item?.user_type == 1
          ? "Vendor"
          : (item?.user_type == 2 && "Property Manager") || "N/A",
      Status:
        item?.status == 1
          ? "Active"
          : (item?.status == 0 && "Temporarily Banned") || "-",
    };
  });

  return (
    <Layout>
      <div className="right-top">
        <div className="heading-top">
          <h2>Customer Management</h2>
        </div>
        <hr />
        <Container fluid>
          <div className="cmn-top-fields">
            <Row>
              <Col xxl={3} xl={3} lg={4} md={4}>
                <Form.Group>
                  <Form.Control
                    type="search"
                    placeholder="Keyword Search.."
                    onChange={(e) => setSearch(e.target.value)}
                  />
                </Form.Group>
              </Col>

              <Col
                xxl={9}
                xl={9}
                lg={8}
                md={8}
                className="d-flex justify-content-end align-items-center"
              >
                <div className="cmn-btn">
                  <DownloadCsv data={data} />
                </div>
              </Col>
            </Row>
          </div>
        </Container>
        <hr />
        <Container fluid>
          <div className="product-tab">
            <ul>
              <li>
                <Link to="/admin/UserManagement">
                  ALL <span>{dash?.totalUsers}</span>
                </Link>
              </li>
              <li>
                <Link to="/admin/CustomerManagement" className="active-tab">
                  Customer <span> {dash?.totalCustomers}</span>
                </Link>
              </li>
              <li>
                <Link to="/admin/VendorManagement">
                  Vendor <span>{dash?.totalVendors}</span>
                </Link>
              </li>
              <li>
                <Link to="/admin/ManagePropertyManagers">
                  Property Managers <span>{dash?.totalPropertyManager}</span>
                </Link>
              </li>
            </ul>
          </div>
        </Container>
      </div>
      <Container fluid>
        <div className="results-sec">
          <Row>
            <Col lg={6} className="d-flex align-items-center">
              <div className="show-results">
                Showing {1 + (page - 1) * limit} -{" "}
                {users?.length + (page - 1) * limit} of {totalUsers} results
              </div>
            </Col>
            <Col lg={6}>
              <div className="select-result d-flex align-items-center justify-content-end">
                <span>Results per page</span>{" "}
                <Form.Select
                  onChange={(e) => setLimit(e.target.value)}
                  aria-label="Default select example"
                >
                  <option value="5">5</option>
                  <option value="10">10</option>
                  <option value="15">15</option>
                </Form.Select>
              </div>
            </Col>
          </Row>
        </div>
      </Container>

      <Container fluid className="px-0">
        <div class="table-responsive">
          <Table size="sm" className="table-cmn">
            <thead>
              <tr>
                <th>S.No</th>
                <th>First Name</th>
                <th>Last Name</th>
                <th>Email</th>
                <th>Phone Number </th>
                <th>Location</th>
                <th>Profile Image</th>
                <th>Account Status</th>
              </tr>
            </thead>
            <tbody>
              {users?.map((item, i) => {
                return (
                  <tr key={item?._id}>
                    <td>{i + 1 + (page - 1) * limit}</td>
                    <td>
                      <Link to={`/admin/CustomerProfile/${item?._id}`}>
                        {item?.first_name || "N/A"}
                      </Link>
                    </td>
                    <td>{item?.last_name || "N/A"}</td>
                    <td>{item?.email || "N/A"}</td>
                    <td>{item?.phone_number || "N/A"}</td>
                    <td>{item?.city || "N/A"}</td>
                    <td className="user-img">
                      <img
                        src={
                          item?.profile_image
                            ? BASE_URL + "/" + item?.profile_image
                            : require("../Assets/Images/user.png")
                        }
                      />
                    </td>
                    <td className="select-box">
                      <Form.Select
                        aria-label="Default select example"
                        onChange={(e) =>
                          handleTempBan(e.target.value, item?._id)
                        }
                        name="status"
                        value={item?.status}
                      >
                        <option value="0">Temporary Banned</option>
                        <option value="1">Active</option>
                      </Form.Select>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
          {users?.length == 0 && (
            <p className="no-data-found">No data is available yet.</p>
          )}
        </div>
      </Container>

      <div className="next-btn-fix">
        <Pagination totalStuff={totalUsers} limit={limit} setPage={setPage} />
      </div>
    </Layout>
  );
}
