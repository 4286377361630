import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";
import Layout from "../Components/Layout/Layout";
import { useDispatch, useSelector } from "react-redux";
import { deleteAdmin, getAllAdmin } from "../redux/actions/adminActions";
import moment from "moment";
import { Button } from "react-bootstrap";
import Pagination from "../Components/Layout/elements/Pagination";

export default function Administrators() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const BASE_URL = process.env.REACT_APP_HOST_NAME;
  const { admins, totalAdmins } = useSelector((state) => ({
    admins: state.adminSlice.admins,
    totalAdmins: state.adminSlice.totalAdmins,
  }));

  const [search, setSearch] = useState("");

  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);

  useEffect(() => {
    dispatch(
      getAllAdmin({
        page: search ? 1 : page,
        limit: limit,
        search: search,
        user_type: 0,
      })
    );
  }, [page, limit, search]);

  console.log(admins, totalAdmins);
  return (
    <Layout>
      <div className="right-top">
        <div className="heading-top">
          <h2>All Admin</h2>
        </div>
        <hr />
        <Container fluid>
          <div className="cmn-top-fields">
            <Row>
              <Col xxl={3} xl={3} lg={3} md={6}>
                <Form.Group>
                  <Form.Control
                    type="search"
                    placeholder="Keyword Search.."
                    onChange={(e) => setSearch(e.target.value)}
                  />
                </Form.Group>
              </Col>

              <Col xxl={2} xl={3} lg={3} md={6}>
                {/* <Form.Select aria-label="Default select example">
                  <option>Created Date</option>
                  <option value="1">24</option>
                  <option value="2">25</option>
                  <option value="3">26</option>
                </Form.Select> */}
              </Col>
              <Col xxl={2} xl={3} lg={3} md={6}>
                {/* <Form.Select aria-label="Default select example">
                  <option>Role</option>
                  <option value="1">24</option>
                  <option value="2">25</option>
                  <option value="3">26</option>
                </Form.Select> */}
              </Col>
              <Col
                xxl={5}
                xl={3}
                lg={3}
                md={6}
                className="d-flex justify-content-end align-items-center"
              >
                <div className="cmn-btn">
                  <Link to="/admin/Create-Admin"> CREATE ADMIN</Link>{" "}
                </div>
              </Col>
            </Row>
          </div>
        </Container>
        <hr />
      </div>
      <Container fluid>
        <div className="results-sec">
          <Row>
            <Col lg={6} className="d-flex align-items-center">
              <div className="show-results">
                Showing {1 + (page - 1) * limit} -{" "}
                {admins?.length + (page - 1) * limit} of {totalAdmins} results
              </div>
            </Col>
            <Col lg={6}>
              <div className="select-result d-flex align-items-center justify-content-end">
                <span>Results per page</span>{" "}
                <Form.Select
                  onChange={(e) => setLimit(e.target.value)}
                  aria-label="Default select example"
                >
                  <option value="10">10</option>
                  <option value="15">15</option>
                  <option value="20">20</option>
                  <option value="25">25</option>
                </Form.Select>
              </div>
            </Col>
          </Row>
        </div>
      </Container>

      <Container fluid className="px-0">
        <div class="table-responsive">
          <Table size="sm" className="table-cmn">
            <thead>
              <tr>
                <th>ID #</th>
                <th>Created</th>
                <th>Name</th>
                <th>Role</th>
                <th>Permissions </th>
                <th>Last Active</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {admins?.map((item, i) => {
                return (
                  <tr>
                    <td>{i + 1}</td>
                    <td>
                      {moment(item?.createdAt).format("DD/MM/YYYY") || "N/A"}
                      <br />
                    </td>
                    <td>{item?.full_name}</td>
                    <td>{item?.role == "admin" ? "Admin" : "Super admin"}</td>
                    <td>
                      {item?.role == "superadmin"
                        ? "Full Access"
                        : "Partial Access"}
                    </td>
                    <td>
                      {(item?.lastLogin &&
                        moment(item?.lastLogin).format("DD/MM/YYYY, h:mm")) ||
                        "N/A"}
                      <br />
                    </td>
                    <td>
                      <Button
                        onClick={() =>
                          navigate(`/admin/Create-Admin?id=${item?._id}`)
                        }
                        className="account-btn"
                      >
                        Edit Access
                      </Button>{" "}
                      <Button
                        onClick={() => dispatch(deleteAdmin(item?._id))}
                        className="account-btn"
                      >
                        DELETE
                      </Button>{" "}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
          {admins?.length == 0 && (
            <p className="no-data-found">No data is available yet.</p>
          )}

          {/* <div className="progress-line">Under Progress</div> */}
        </div>
      </Container>

      <div className="next-btn-fix">
        <Pagination totalStuff={totalAdmins} limit={limit} setPage={setPage} />
      </div>
    </Layout>
  );
}
