import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";
import Layout from "../Components/Layout/Layout";
import { Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  ApprovedAccount,
  activationRequest,
  approvedAccount,
  getAccountActivationList,
} from "../redux/actions/adminActions";
import Pagination from "../Components/Layout/elements/Pagination";
import { toast } from "react-toastify";

export default function AccountActivation() {
  const dispatch = useDispatch();
  const BASE_URL = process.env.REACT_APP_HOST_NAME;
  // const { activationList, totalActivation } = useSelector((state) => ({
  //   activationList: state.userMgmtSlice.ActivationList,
  //   totalActivation: state.userMgmtSlice.totalActivation,
  // }));
  const activationList = useSelector(
    (state) => state.userMgmtSlice.ActivationList
  );

  console.log(activationList, "list");

  const [search, setSearch] = useState("");
  const [flag, setflag] = useState(true);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(5);
  const [activeTab, setActiveTab] = useState();

  useEffect(() => {
    dispatch(
      getAccountActivationList({
        page: search ? 1 : page,
        limit: limit,
        search: search,
        type: 0,
      })
    );
  }, [page, limit, search, flag]);
  const handleAccount = (id) => {
    console.log(id);
    dispatch(ApprovedAccount({ userId: id, type: 0 })).then((res) => {
      if (res?.payload?.success) {
        toast.success(res?.payload?.message);
        setflag(!flag);
      } else {
        toast.error(res?.payload?.message);
      }
    });
  };

  // console.log(activationList, totalActivation, "orders");

  return (
    <Layout>
      <div className="right-top">
        <div className="heading-top">
          <h2>Account Activation Requests</h2>
        </div>
        <hr />
        <Container fluid>
          <div className="cmn-top-fields">
            <Row>
              <Col xxl={3} xl={3} lg={4} md={4}>
                <Form.Group>
                  <Form.Control
                    type="search"
                    placeholder="Keyword Search.."
                    onChange={(e) => setSearch(e.target.value)}
                  />
                </Form.Group>
              </Col>

              <Col
                xxl={9}
                xl={9}
                lg={8}
                md={8}
                className="d-flex justify-content-end align-items-center"
              >
                {/* <div className="cmn-btn">
                  <Link to="#">DOWNLOAD</Link>
                </div> */}
              </Col>
            </Row>
          </div>
        </Container>
        <hr />
        <Container fluid>
          <div className="product-tab">
            <ul>
              <li>
                <Link to="#" className="active-tab">
                  ALL <span></span>
                </Link>
              </li>
              <li>
                <Link to="/account-activation-rejected">
                  Rejected <span> </span>
                </Link>
              </li>
            </ul>
          </div>
        </Container>
      </div>

      <Container fluid>
        <div className="results-sec">
          <Row>
            <Col lg={6} className="d-flex align-items-center">
              <div className="show-results">
                Showing {1 + (page - 1) * limit} -{" "}
                {activationList?.users?.length + (page - 1) * limit} of{" "}
                {activationList?.totalCount} results
              </div>
            </Col>
            <Col lg={6}>
              <div className="select-result d-flex align-items-center justify-content-end">
                <span>Results per page</span>{" "}
                <Form.Select
                  onChange={(e) => setLimit(e.target.value)}
                  aria-label="Default select example"
                >
                  <option value="10">10</option>
                  <option value="15">15</option>
                  <option value="20">20</option>
                  <option value="25">25</option>
                </Form.Select>
              </div>
            </Col>
          </Row>
        </div>
      </Container>

      <Container fluid className="px-0">
        <div class="table-responsive">
          <Table size="sm" className="table-cmn">
            <thead>
              <tr>
                <th>S.No</th>
                <th>First Name</th>
                <th>Last Name</th>
                <th>Email</th>
                <th>Phone Number </th>
                <th>Address</th>
                <th>Profile Image</th>
                <th>Role</th>
                {/* <th>Position</th> */}
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {activationList?.users?.map((item, index) => {
                return (
                  <tr>
                    <td>{index + 1}</td>
                    <td>{item?.first_name}</td>
                    <td>{item?.last_name}</td>
                    <td>{item?.email}</td>
                    <td>{item?.phone_number || "N/A"}</td>
                    <td>{item?.address || "N/A"}</td>
                    <td className="user-img">
                      <img
                        src={
                          item?.profile_image
                            ? BASE_URL + "/" + item?.profile_image
                            : require("../Assets/Images/user.png")
                        }
                      />
                    </td>
                    <td>{item?.user_type === 1 ? "Vendor" : " Manager"}</td>

                    <td>
                      <Button
                        onClick={() =>
                          dispatch(
                            ApprovedAccount({ userId: item?._id, type: 1 })
                          ).then((res) => {
                            if (res?.payload?.success) {
                              toast.success(res?.payload?.message);
                              setflag(!flag);
                            } else {
                              toast.error(res?.payload?.message);
                            }
                          })
                        }
                        className="account-btn"
                      >
                        {item?.is_approved == 1 ? "Accepted" : "Accept"}
                      </Button>{" "}
                      <Button
                        onClick={() => handleAccount(item?._id)}
                        className="account-btn"
                      >
                        {item?.is_approved == 0 ? "Rejected" : "Reject"}
                      </Button>{" "}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
          {/* {activationList?.length == 0 && (
            <p className="no-data-found"> No data is available yet.</p>
          )} */}
        </div>
      </Container>

      <div className="next-btn-fix">
        <Pagination
          totalStuff={activationList?.totalCount}
          limit={limit}
          setPage={setPage}
        />
      </div>
    </Layout>
  );
}
