import { createAsyncThunk } from "@reduxjs/toolkit";
import adminApi from "../../services/adminApi";
import { toast } from "react-toastify";

const getFaq = createAsyncThunk("getFaq", async ({ page, limit, search }) => {
  const { data } = await adminApi.get(
    `/getFaq?page=${page}&limit=${limit}&search=${search}`
  );
  return data;
});

const getFaqById = createAsyncThunk("getFaqById", async (id) => {
  const { data } = await adminApi.get(`/getFaqById?id=${id}`);
  return data;
});

const updateFaq = createAsyncThunk("updateFaq", async (formdata) => {
  const { data } = await adminApi.put("/updateFaq", formdata);
  return data;
});

const deleteFaq = createAsyncThunk("deleteFaq", async (id) => {
  const { data } = await adminApi.delete(`/deleteFaq?id=${id}`);

  data?.success && toast.success(data?.message);
  !data?.success && toast.success(data?.message);

  return data;
});

const addFAQ = createAsyncThunk("addFAQ", async (data, thunkAPI) => {
  const response = await adminApi.post("/addFAQ", data);
  return response.data;
});

const getOrderDetails = createAsyncThunk("getOrderDetails", async (id) => {
  const { data } = await adminApi.get(`/getOrderDetails?orderId=${id}`);
  return data;
});

const createNotification = createAsyncThunk(
  "createNotification",
  async (data, thunkAPI) => {
    const response = await adminApi.post("/addNotification", data);
    return response.data;
  }
);

const getAllNotifications = createAsyncThunk(
  "getAllNotifications",
  async ({ page, limit, search }) => {
    const { data } = await adminApi.get(
      `/getAllNotifications?page=${page}&limit=${limit}&search=${search}`
    );
    return data;
  }
);

const deleteNotification = createAsyncThunk(
  "deleteNotification",
  async (id) => {
    const { data } = await adminApi.delete(`/deleteNotification?id=${id}`);

    data?.success && toast.success(data?.message);
    !data?.success && toast.success(data?.message);

    return data;
  }
);

const getAllAdmin = createAsyncThunk(
  "getAllAdmin",
  async ({ page, limit, search }) => {
    const { data } = await adminApi.get(
      `/getAllAdmin?page=${page}&limit=${limit}&search=${search}`
    );
    return data;
  }
);

const getAdminById = createAsyncThunk("getAdminById", async (id) => {
  const { data } = await adminApi.get(`/getAdminById?id=${id}`);
  return data;
});

const updateAdmin = createAsyncThunk("updateAdmin", async (formdata) => {
  const { data } = await adminApi.put("/updateAdmin", formdata);
  return data;
});

const deleteAdmin = createAsyncThunk("deleteAdmin", async (id) => {
  const { data } = await adminApi.delete(`/deleteAdmin?id=${id}`);

  data?.success && toast.success(data?.message);
  !data?.success && toast.success(data?.message);

  return data;
});

const createAdmin = createAsyncThunk("createAdmin", async (data, thunkAPI) => {
  const response = await adminApi.post("/createAdmin", data);
  return response.data;
});

const activationRequest = createAsyncThunk(
  "activationRequest",
  async ({ page, limit, search }) => {
    const { data } = await adminApi.get(
      `/activationRequest?page=${page}&limit=${limit}&search=${search}`
    );
    return data;
  }
);

const approvedAccount = createAsyncThunk(
  "approvedAccount",
  async (data, thunkAPI) => {
    const response = await adminApi.put("/approvedAccount", data);
    return response.data;
  }
);

const getAllOrders = createAsyncThunk(
  "getAllOrders",
  async ({ page, limit, search, orderStatus, propertyFilter }) => {
    const { data } = await adminApi.get(
      `/orderDetails?page=${page}&limit=${limit}&search=${search}&type=${orderStatus}&propertyFilter=${propertyFilter}`
    );
    return data;
  }
);

const getAllUsers = createAsyncThunk(
  "getAllUsers",
  async ({ page, limit, search }) => {
    const { data } = await adminApi.get(
      `/getAllUsers?page=${page}&limit=${limit}&search=${search}`
    );
    return data;
  }
);

const dashboard = createAsyncThunk("dashboard", async () => {
  const { data } = await adminApi.get("/dashboard");
  return data;
});

const getUsers = createAsyncThunk(
  "getUsers",
  async ({ page, limit, search, user_type }) => {
    const { data } = await adminApi.get(
      `/getUsers?page=${page}&limit=${limit}&search=${search}&user_type=${user_type}`
    );
    return data;
  }
);

const getUserProfileById = createAsyncThunk(
  "getUserProfileById",
  async (id, thunkAPI) => {
    const response = await adminApi.get(`/getUserProfileById?id=${id}`);
    return response.data;
  }
);

const deleteUserAccount = createAsyncThunk(
  "deleteUserAccount",
  async (id, thunkAPI) => {
    const response = await adminApi.delete(`/deleteUserAccount?id=${id}`);
    return response.data;
  }
);

const suspendUserAccount = createAsyncThunk(
  "suspendUserAccount",
  async (data, thunkAPI) => {
    const response = await adminApi.put(`/suspendedAccount`, data);
    return response.data;
  }
);

const getUserDetails = createAsyncThunk("getUserDetails", async (id) => {
  const { data } = await adminApi.get(`/getUserDetails/?user_id=${id}`);
  return data;
});

const addProperty = createAsyncThunk("addProperty", async (notObj) => {
  const { data } = await adminApi.post("/createProperties", notObj);
  return data;
});

const imagesaveAction = createAsyncThunk("imagesaveAction", async (notObj) => {
  const { data } = await adminApi.post("/imagesave", notObj);
  return data;
});

const tempBannedAccount = createAsyncThunk("tempBannedAccount", async (obj) => {
  const { data } = await adminApi.put("/tempBannedAccount", obj);
  return data;
});
const tempBannedAccountCustomer = createAsyncThunk(
  "tempBannedAccountCustomer",
  async (obj) => {
    const { data } = await adminApi.put("/tempBannedAccount", obj);
    return data;
  }
);

const editUserDetails = createAsyncThunk(
  "editUserDetails",
  async (formdata) => {
    const { data } = await adminApi.put("/editUserDetails", formdata);
    return data;
  }
);

const getAllManageProperties = createAsyncThunk(
  "getAllManageProperties",
  async ({ page, limit, search }) => {
    const { data } = await adminApi.get(
      `/getPropertyList?page=${page}&limit=${limit}&search=${search}`
    );
    return data;
  }
);

const getProperty = createAsyncThunk("getProperty", async (id) => {
  const { data } = await adminApi.get(`/getProperty?id=${id}`);
  return data;
});

const updateProperty = createAsyncThunk("updateProperty", async (formdata) => {
  const { data } = await adminApi.post("/updateProperty", formdata);
  return data;
});
const deleteProperty = createAsyncThunk("deleteProperty", async (id) => {
  const { data } = await adminApi.delete(`/deleteProperty?id=${id}`);

  data?.success && toast.success(data?.message);
  !data?.success && toast.success(data?.message);

  return data;
});

const createService = createAsyncThunk("createService", async (obj) => {
  const { data } = await adminApi.post(`/createService`, obj);

  data?.success && toast.success(data?.message);
  !data?.success && toast.success(data?.message);

  return data;
});

const getServiceList = createAsyncThunk(
  "getServiceList",
  async ({ page, limit, search, propertyFilter }) => {
    const { data } = await adminApi.get(
      `/getServiceList?page=${page}&limit=${limit}&search=${search}&propertyFilter=${propertyFilter}`
    );
    return data;
  }
);

const changeUserPass = createAsyncThunk("changeUserPass", async (passObj) => {
  const { data } = await adminApi.put("/changeUserPass", passObj);

  data?.success && toast.success(data?.message);
  !data?.success && toast.success(data?.message);

  return data;
});

const getService = createAsyncThunk("getService", async (id) => {
  const { data } = await adminApi.get(`/getService?id=${id}`);
  return data;
});

const updateService = createAsyncThunk("updateService", async (formdata) => {
  const { data } = await adminApi.put("/updateService", formdata);
  return data;
});

const deleteService = createAsyncThunk("deleteService", async (id) => {
  const { data } = await adminApi.delete(`/deleteService?id=${id}`);

  // data?.success && toast.success(data?.message);
  // !data?.success && toast.success(data?.message);

  return data;
});

const forgotPassword = createAsyncThunk(
  "forgotPassword",
  async (data, thunkAPI) => {
    const response = await adminApi.post("/auth/forgotPass", data);
    return response.data;
  }
);

const resetPassword = createAsyncThunk(
  "resetPassword",
  async (data, thunkApi) => {
    const response = await adminApi.put(`/auth/resetPass`, data);
    return response.data;
  }
);

const verifyOtp = createAsyncThunk("verifyOtp", async (data, thunkAPI) => {
  const response = await adminApi.post("/auth/verifyOtp", data);
  return response.data;
});

const getAccountActivationList = createAsyncThunk(
  "getAccountActivationList",
  async ({ page, limit, search, type }) => {
    const { data } = await adminApi.get(
      `/activationRequest?page=${page}&limit=${limit}&name=${search}&type=${type}`
    );
    return data;
  }
);
const ApprovedAccount = createAsyncThunk("ApprovedAccount", async (details) => {
  const { data } = await adminApi.put(`/approvedAccount`, details);

  return data;
});

const getSupport = createAsyncThunk(
  "getSupport",
  async ({ page, limit, search }) => {
    const { data } = await adminApi.get(
      `/getSupports?page=${page}&limit=${limit}&search=${search}`
    );
    return data;
  }
);
const getSupportchat = createAsyncThunk("getSupportchat", async (id) => {
  const { data } = await adminApi.get(`/getSupportDetails?support_id=${id}`);
  return data;
});
const changeSupportStatus = createAsyncThunk(
  "changeSupportStatus",
  async (details) => {
    const { data } = await adminApi.post(`/changeSupportStatus`, details);
    return data;
  }
);
const adminchatmessages = createAsyncThunk(
  "adminchatmessages",
  async (details) => {
    const { data } = await adminApi.post(`/sendAdminSupportMessage`, details);
    return data;
  }
);

export {
  getFaq,
  getFaqById,
  updateFaq,
  deleteFaq,
  addFAQ,
  getOrderDetails,
  createNotification,
  deleteNotification,
  getAllNotifications,
  getAllAdmin,
  getAdminById,
  updateAdmin,
  deleteAdmin,
  createAdmin,
  activationRequest,
  approvedAccount,
  getAllOrders,
  dashboard,
  getUsers,
  getUserDetails,
  changeUserPass,
  editUserDetails,
  getUserProfileById,
  deleteUserAccount,
  suspendUserAccount,
  getAllUsers,
  addProperty,
  getAllManageProperties,
  getProperty,
  updateProperty,
  deleteProperty,
  createService,
  getServiceList,
  getService,
  updateService,
  deleteService,
  tempBannedAccount,
  tempBannedAccountCustomer,
  imagesaveAction,
  forgotPassword,
  resetPassword,
  verifyOtp,
  getAccountActivationList,
  ApprovedAccount,
  getSupport,
  changeSupportStatus,
  getSupportchat,adminchatmessages
};
