import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";
import Layout from "../Components/Layout/Layout";
import { Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteService,
  getAllManageProperties,
  getServiceList,
} from "../redux/actions/adminActions";
import Pagination from "../Components/Layout/elements/Pagination";
import { toast } from "react-toastify";

export default function ManageServices() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const BASE_URL = process.env.REACT_APP_HOST_NAME;
  const {
    allManageServiceProperties,
    totalManageServiceProperties,
    allManageProperties,
  } = useSelector((state) => ({
    allManageServiceProperties:
      state.managePropertySlice.allManageServiceProperties,
    totalManageServiceProperties:
      state.managePropertySlice.totalManageServiceProperties,
    allManageProperties: state.managePropertySlice.allManageProperties,
  }));

  const [search, setSearch] = useState("");
  const [propertyFilter, setPropertyFilter] = useState();

  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);

  useEffect(() => {
    dispatch(
      getServiceList({
        page: search ? 1 : page,
        limit: limit,
        search: search,
        user_type: 0,
        propertyFilter: propertyFilter,
      })
    );
  }, [page, limit, search, propertyFilter]);

  useEffect(() => {
    dispatch(
      getAllManageProperties({
        page: 1,
        limit: 1000000000,
        search: "",
        user_type: 0,
      })
    );
  }, []);

  console.log(totalManageServiceProperties, "allManageServiceProperties");
  return (
    <Layout>
      <div className="right-top">
        <div className="heading-top">
          <h2>Manage Services</h2>
        </div>
        <hr />
        <Container fluid>
          <div className="cmn-top-fields">
            <Row>
              <Col xxl={3} xl={3} lg={4} md={4}>
                <Form.Group>
                  <Form.Control
                    type="search"
                    placeholder="Keyword Search.."
                    onChange={(e) => setSearch(e.target.value)}
                  />
                </Form.Group>
              </Col>
              <Col xxl={6} xl={6} lg={4} md={5}>
                <Form.Select
                  aria-label="Default select example"
                  onChange={(e) => setPropertyFilter(e.target.value)}
                >
                  <option value="">Select Property</option>
                  {allManageProperties?.map((item) => {
                    return (
                      <option value={item?._id}>{item?.property_name}</option>
                    );
                  })}
                </Form.Select>
              </Col>
              <Col
                xxl={3}
                xl={3}
                lg={4}
                md={2}
                className="d-flex justify-content-end align-items-center"
              >
                {/* <div className="cmn-btn mx-2">
                  <Link to="#">Download</Link>
                </div> */}
                <div className="cmn-btn">
                  <Link to="/admin/AddServices">ADD</Link>
                </div>
              </Col>
            </Row>
          </div>
        </Container>
        <hr />
      </div>
      <Container fluid>
        <div className="results-sec">
          <Row>
            <Col lg={6} className="d-flex align-items-center">
              <div className="show-results">
                Showing {1 + (page - 1) * limit} -{" "}
                {allManageServiceProperties?.length + (page - 1) * limit} of{" "}
                {totalManageServiceProperties} results
              </div>
            </Col>
            <Col lg={6}>
              <div className="select-result d-flex align-items-center justify-content-end">
                <span>Results per page</span>{" "}
                <Form.Select
                  onChange={(e) => setLimit(e.target.value)}
                  aria-label="Default select example"
                >
                  <option value="10">10</option>
                  <option value="15">15</option>
                  <option value="20">20</option>
                  <option value="25">25</option>
                </Form.Select>
              </div>
            </Col>
          </Row>
        </div>
      </Container>

      <Container fluid className="px-0">
        <div class="table-responsive">
          <Table size="sm" className="table-cmn">
            <thead>
              <tr>
                <th>S.No</th>
                <th>Service Name</th>
                <th>Description</th>
                <th>Property</th>
                <th>Picture</th>
                <th>Price </th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {allManageServiceProperties?.map((item, i) => {
                return (
                  <tr key={item?._id}>
                    <td>{i + 1 + (page - 1) * limit}</td>
                    <td>{item?.service_name || "N/A"}</td>
                    <td className="table-desc">
                      <p>{item?.service_description || "N/A"}</p>
                    </td>
                    <td>
                      {item?.property?.property_name}
                    </td>
                    <td className="user-img">
                      <img
                        src={
                          item?.service_image
                            ? BASE_URL + "/" + item?.service_image
                            : require("../Assets/Images/user.png")
                        }
                      />
                    </td>
                    <td>${item?.service_price}</td>
                    <td>
                      <Button
                        className="account-btn"
                        onClick={() =>
                          navigate(`/admin/AddServices?id=${item?._id}`)
                        }
                      >
                        Edit
                      </Button>{" "}
                      <Button
                        onClick={() => {
                          dispatch(deleteService(item?._id)).then((res) => {
                            if (res) {
                              toast.success(res?.message);
                            } else {
                              toast.error(res?.message);
                            }
                          });
                        }}
                        className="account-btn"
                      >
                        Delete
                      </Button>{" "}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
          {allManageServiceProperties?.length == 0 && (
            <p className="no-data-found">No data is available yet.</p>
          )}
        </div>
      </Container>

      <div className="next-btn-fix">
        <Pagination
          totalStuff={totalManageServiceProperties}
          limit={limit}
          setPage={setPage}
        />
      </div>
    </Layout>
  );
}
