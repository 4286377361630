import React from "react";
import Layout from "../Components/Layout/Layout";
import { Container, Row, Col, Card, Form, Button } from "react-bootstrap";
import { Formik } from "formik";
import { useDispatch } from "react-redux";
import { changePassword } from "../redux/actions/adminAuth";

export default function ChangePassword() {
  const dispatch = useDispatch();

  return (
    <>
      <Layout>
        <div className="right-top">
          <div className="heading-top">
            <h2>Change Password</h2>
          </div>
          <hr />
        </div>
        <Container fluid>
          <Row className="justify-content-md-center mt-4">
            <Col lg="7" md="auto">
              <Card className="border-0 p-5 rounded-card customer-form-new">
                <h2 className="text-center">Change Password</h2>
                <Formik
                  initialValues={{ oldPassword: "", newPassword: "", confirmPassword: "" }}
                  validate={(values) => {
                    const errors = {};
                    if (!values.oldPassword) {
                      errors.oldPassword = "Required*";
                    }
                    if (!values.newPassword) {
                      errors.newPassword = "Required*";
                    } else if (values.newPassword < 8) {
                      errors.newPassword = "Password must be of 8 characters";
                    }

                    if (!values.confirmPassword) {
                      errors.confirmPassword = "Required";
                    } else if (values.newPassword !== values.confirmPassword) {
                      errors.confirmPassword = "Passwords doesn't match";
                    }

                    return errors;
                  }}
                  onSubmit={(values, { setSubmitting }) => {

                    dispatch(changePassword({ passObj: values }));

                    setSubmitting(false);
                  }}
                >
                  {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,
                  }) => (
                    <Form onSubmit={handleSubmit} className="change-password-form px-5">
                      <Row className="mb-4">
                        <Form.Group as={Col} controlId="formGridEmail">
                          <Form.Label>Old Password</Form.Label>
                          <Form.Control
                            type="password"
                            placeholder="Old Password"
                            name="oldPassword"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.oldPassword}
                          />
                          <span className="formik-errors">
                            {errors.oldPassword && touched.oldPassword && errors.oldPassword}
                          </span>
                        </Form.Group>
                      </Row>
                      <Row className="mb-4">
                        <Form.Group as={Col} controlId="formGridEmail">
                          <Form.Label>New Password</Form.Label>
                          <Form.Control
                            type="password"
                            placeholder="New Password"
                            name="newPassword"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.newPassword}
                          />
                          <span className="formik-errors">
                            {errors.newPassword && touched.newPassword && errors.newPassword}
                          </span>
                        </Form.Group>
                      </Row>
                      <Row className="mb-4">
                        <Form.Group as={Col} controlId="formGridEmail">
                          <Form.Label>Confirm Password</Form.Label>
                          <Form.Control
                            type="password"
                            placeholder="Confirm Password"
                            name="confirmPassword"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.confirmPassword}
                          />
                          <span className="formik-errors">
                            {errors.confirmPassword && touched.confirmPassword && errors.confirmPassword}
                          </span>
                        </Form.Group>
                      </Row>
                      <Form.Group
                        className="mt-4  mb-5 model-btn text-center"
                        controlId="formGridAddress2"
                      >
                        <div class="cmn-btn">
                          <button type="submit">SAVE</button>
                        </div>
                      </Form.Group>
                    </Form>
                  )}
                </Formik>
              </Card>
            </Col>
          </Row>
        </Container>
      </Layout>
    </>
  );
}
